.menu_image {
  width: 100%;
  text-align: center;
}
.web_action_wish span b {
  font-weight: 400 !important;
}
.cart_section li {
  margin-right: 12px;
  list-style: none;
  display: inline-block;
  position: relative;
  text-align: center;
}

.cart_section li span {
  color: rgb(50, 32, 28);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.cart_section1 li b {
  position: absolute;
  right: -7px;
  top: -3px;
  background-color: rgb(223 159 26);
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  font-size: 12px;
  /* color: rgb(255, 255, 255); */
  font-weight: 600;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 50%);
}

.cart_section li:last-child {
  margin-left: 20px;
}

.cart_section li i {
  color: rgb(255, 255, 255);
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
  font-size: 14px;
}

.cart_section i img {
  border-radius: 5px;
}

.cart_section_1 li {
  margin-right: 15px;
  list-style: none;
  display: inline-block;
  position: relative;
  text-align: center;
}

.cart_section_1 li span {
  color: rgb(50, 32, 28);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.cart_section1 li b {
  position: absolute;
  right: -7px;
  top: -3px;
  background-color: rgb(223 159 26);
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  font-size: 12px;
  /* color: rgb(255, 255, 255); */
  font-weight: 600;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 50%);
}

/* .cart_section_1 li:last-child {
    margin-left: 20px;
} */

.cart_section_1 li i {
  color: rgb(255, 255, 255);
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
  font-size: 14px;
}

.cart_section_1 i img {
  border-radius: 5px;
}

.cart_section_1 li:hover .submenu {
  transform: translateY(0px);
  transition: 0.5s all ease-in-out;
  opacity: 1 !important;
  visibility: visible !important;
  cursor: pointer;
}

ul.cart_section_1 {
  margin-bottom: 0px;
  width: 40%;
}

.logo {
  text-align: center;
  width: 20%;
}

.align-center {
  display: inline-block;
  text-align: center;
}

.nav-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: normal;
}

/* .menuH li:hover{
    background:  rgb(54 35 33);
    color: rgb(255, 255, 255);
} */
.menuH li {
  padding: 6x 10px !important;
  color: rgb(50, 32, 28);
  font-family: "varela";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.857px;
  text-transform: uppercase;
  list-style: none;
  display: inline-block;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.menuH li:hover {
  transition: 0.3s all ease-in;
  border-bottom: 2px solid rgb(44, 29, 27);
}

ul.cart_section {
  width: 40%;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.menu_main {
  z-index: 1;
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  box-shadow: 0px 0px 5px rgb(64 0 0 / 20%);
}

.menu_main .row {
  align-items: center;
}

.header_menu_list {
  background: rgba(255, 236, 231, 1);
  text-align: center;
  border-bottom: 2px solid rgba(177, 127, 81, 1);
}

.header_menu_list .menuH {
  margin: 0;
  padding: 10px;
}

.logo img {
  cursor: pointer;
}

/* .menuH_container{
    display: none;
} */
/* .menubar{
    display: none;
} */

.header_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
}

.header_main .menuH {
  margin-bottom: 0px;
}

.menuH li {
  list-style: none;
  display: inline-block;
  margin: 0px 12px;
}

.submenu {
  position: absolute;
  left: -102px;
  top: 50px;
  width: 140px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 6px rgb(68 68 68 / 40%);
  border-radius: 2px;
  border-top: 3px solid rgb(54 35 33);
  transform: translateY(70px);
  opacity: 0;
  visibility: hidden;
  transition: 0.5s all ease-in-out;
  z-index: 1;
}

.cart_section li:hover .submenu {
  transform: translateY(0px);
  transition: 0.5s all ease-in-out;
  opacity: 1 !important;
  visibility: visible !important;
  cursor: pointer;
}

/* .cart_section li:first-child {
    margin-right: 20px;
} */

.submenu li {
  float: left;
  width: 100%;
  text-align: left;
  margin: 0px !important;
  padding: 10px 15px;
  font-size: 13px;
  color: rgb(51, 51, 51);
  border-bottom: 1px solid rgb(229 229 229);
  font-family: "Montserrat";
}

.submenu li:last-child {
  border-bottom: none !important;
}

.dailyrate {
  left: 0px;
  width: 180px !important;
}

.dailyrate li {
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .header_menu_list .menuH {
    margin: 0;
    padding: 5px;
  }

  .sidemenu_list ul {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .sidemenu_list li {
    padding: 8px !important;
    width: 40px !important;
    height: 40px !important;
    background: rgb(255, 255, 255) !important;
    margin-right: 4px !important;
    border-radius: 4px !important;
  }

  .menuH_container {
    display: block;
  }

  .mobileMenu_btn {
    float: right;
    padding: 10px;
    /* background-color: #40000030; */
    background-color: #40000024;
    width: 188px;
  }

  .mobileMenu_btn span {
    float: right;
  }

  .header {
    float: right;
    text-align: left;
    width: 188px;
    background-color: #40000024;
    padding: 10px 16px;
  }

  .menubar {
    position: absolute;
  }

  /* #mobileMenu {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
    background-color: rgb(255 255 255);
    box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 24%);
} */

  .menuH {
    position: fixed;
    right: -25px;
    top: 0px;
    background-color: rgb(51 33 31);
    width: 0;
    height: 100vh;
    z-index: 1;
    transition: 0.5s all ease-in-out;
    box-shadow: 0px 0px 5px rgb(64 0 0 / 20%);
    /* border-top: 4px solid rgb(47 30 29); */
  }

  .toggle_bar {
    width: 25px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0px;
    margin-right: 20px;
  }

  .toggle_bar span {
    float: left;
    width: 100%;
    height: 2px;
    background-color: rgb(51 33 31);
    transition: 0.5s all ease-in-out;
  }

  .fixed_menu {
    right: 0px;
    width: 260px;
    transition: 0.5s all ease-in-out;
  }

  .menuH li {
    /* background: rgb(50, 32, 28); */
    font-family: "varela";
    color: rgb(255, 255, 255);
    float: left;
    width: 100%;
    padding: 10px 20px;
    margin: 0px;
    border-bottom: 1px solid rgb(242 242 242);
  }

  /* .menu_main {
        height: 84px;
        border-bottom: 1px solid rgb(22 34 91);
    } */

  .middlebar {
    height: 36px;
  }

  .toggle_rotate span:nth-child(1) {
    transform: rotate(45deg) translate(3px, 9px);
    transition: 0.5s all ease-in-out;
  }

  .toggle_rotate span:nth-child(2) {
    display: none;
    transition: 0.5s all ease-in-out;
  }

  .toggle_rotate span:nth-child(3) {
    transform: rotate(-45deg) translate(3px, -9px);
    transition: 0.5s all ease-in-out;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  ul.cart_section_1 {
    margin-bottom: 0px;
    width: 40%;
  }

  .logo {
    text-align: center;
    width: 20%;
  }

  ul.cart_section {
    width: 40%;
  }

  .middlebar li {
    padding: 0px 5px;
    font-size: 8px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  ul.cart_section_1 {
    margin-bottom: 0px;
    width: 40%;
  }

  .logo {
    text-align: center;
    width: 20%;
  }

  ul.cart_section {
    width: 40%;
  }

  .middlebar li {
    padding: 0px 10px;
    font-size: 10px;
  }
}

@media (min-width: 1200px) and (max-width: 1365px) {
  ul.cart_section_1 {
    margin-bottom: 0px;
    width: 40%;
  }

  .logo {
    text-align: center;
    width: 20%;
  }

  ul.cart_section {
    width: 40%;
  }
}

/* .sidemenu_list i {
    margin-right: 25px;
} */
/* .sidemenu_list ul{
    display: flex;
    align-items: center;
    justify-content: space-around;
} */
@media (min-width: 320px) and (max-width: 479px) {
  .gold_button {
    right: 5px !important;
    bottom: 5px !important;
  }

  .gold_display1 button.gold_btn {
    width: 80px !important;
    font-size: 8px !important;
    padding: 0px 5px !important;
  }

  .display_gold button.gold_btn {
    width: 100px;
    font-size: 10px;
    padding: 0px 5px;
  }

  .category_name {
    font-size: 10px !important;
  }

  .gold_box {
    margin-bottom: 5px !important;
  }

  /* .cart_section_1 li:last-child {
        margin-left: 15px;
    } */
  .dailyrate {
    z-index: 9999 !important;
    top: 40px;
    left: 0px;
    width: 180px !important;
  }

  .submenu {
    left: 0px;
    top: 30px;
  }

  .logo img {
    cursor: pointer;
    width: 100%;
  }

  .header_main ul {
    margin-bottom: 0px;
  }

  .logo {
    text-align: center;
    /* width: calc(100% - 45px); */
  }

  ul.cart_section {
    width: 25%;
    margin-bottom: 0;
  }

  .cart_section li:first-child {
    margin-right: 12px;
  }

  .cart_section li:last-child {
    margin-left: 12px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .gold_button {
    right: 5px !important;
    bottom: 5px !important;
  }

  .display_gold button.gold_btn {
    width: 100px;
    font-size: 12px;
    padding: 0px 5px;
  }

  .category_name {
    font-size: 10px !important;
  }

  .logo img {
    width: 100%;
  }

  .login_form {
    width: 400px !important;
  }

  .image_sub {
    display: none;
  }
}
