.cart_img img {
  border-radius: 5px;
  width: 100%;
}
.main_cart {
  margin-top: 135px;
  padding: 40px;
  min-height: calc(100vh - 404px);
}
.quantity button {
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  background-color: rgb(54 35 33);
  color: white;
  border: none;
  text-align: center;
}
.cart_note {
  margin-bottom: 25px;
}
.cart_note li {
  font-family: "montserrat";
  font-size: 13px;
  padding: 12px 10px;
}
.detail_note1 {
  text-align: right;
}
.cartData {
  font-family: Varela;
  font-size: 24px;
  color: rgb(54, 35, 33);
  margin-bottom: 15px;
}
.cart_detail_box {
  margin-bottom: 30px;
  border-radius: 10px;
  border-top: 4px solid rgb(177, 127, 81);
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
  /* border: 1px solid rgba(64, 0, 0, 0.129); */
}
.cart_detail_box .price_breakup {
  padding: 14px 10px;
}
.order_sum {
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  border-top: 4px solid rgb(177, 127, 81);
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
  /* border: 1px solid #40000021; */
}

.cartEmpty {
  padding: 185px 0px 30px 0px;
  text-align: center;
  min-height: calc(100vh - 442px);
}
.cartempty_txt {
  font-size: 22px;
  font-weight: 700;
  padding: 13px;
  color: rgb(22, 34, 91);
}

@media (max-width: 768px) {
  .cart_detail_box {
    margin: 22px 0px;
  }

  .cartEmpty {
    padding: 30px;
  }
}
