.order_img img {
    width: 100%;
}

.invoice button {
    /* font-size: 11px; */
    font-family: 'Varela';
    padding: 10px 5px;
    border: none;
    border-radius: 4px;
    color: rgb(50 33 31);
    font-weight: 500;
}
.mainord {
    border-radius: 5px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
    margin-bottom: 36px;
  
    padding: 10px;
}
.mainord .row {
    align-items: center;
    justify-content: space-between;
}
.invoice button {
    float: left;
    width: 100%;

    font-size: 11px;

}


.order_name {
    font-size: 18px;
    /* color: #16225b; */
}
.price{
     font-size: 14px;
     /* color: #16225b; */
}
.product_info ul li {
    font-family: 'Montserrat';
    list-style: none;
    line-height: 23px;
    list-style: none;
    font-size: 12px;
}
.main_order {
    margin-top: 130px;
    padding: 50px;
}