@import url('https://fonts.googleapis.com/css2?family=Rochester&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');

.disable_wifi {
    opacity: 0.25;
    pointer-events: none;
}

.main_wifi1_det {
    padding: 0px;
    margin: 0px;
}

.main_wifi1_det li {
    list-style-type: none;
    float: left;
    width: 33.33%;
    margin-bottom: 15px;
}

.main_wifi1_det img {
    margin-right: 5px;
}

.main_wifi1_det span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #545454;
}

.left_view_room li:last-child a {
    display: block;
}

.left_view_room li a {
    display: none;
}
/* .banner_container {
    margin-top: 141px;
} */
.banner_container img {
    width: 100%;
}
/* .menu_header{
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
} */
.gold_heading {
    text-align: center;
    padding: 40px 0px 20px 0px;
}

.gold_heading span {
    position: relative;
    font-family: Varela;
    text-transform: uppercase;
    color: #C08920;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
}
.gold_heading span::before {
    position: absolute;
    content: '';
    border-bottom: 4px double;
    top: 18px;
    width: 150px;
    left: -160px;
    color: rgba(217, 217, 217, 1);
}
.gold_heading span::after {
    position: absolute;
    content: '';
    border-bottom: 4px double;
    top: 18px;
    width: 150px;
    right: -160px;
    color: rgba(217, 217, 217, 1);
}
.gold_silver {
    text-align: center;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 5px;
    text-transform: uppercase;
    margin-top: 20px;
}
.gold_image img {
    cursor: pointer;
    width: 100%;
}
.gold_collect_cont .gold_image img {
    border-radius: 6px
}
.gold_image {
    padding: 0px 15px;
}

.gold_name {
    color: rgb(50, 32, 28);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.714px;
    text-transform: uppercase;
    padding: 15px 0px;
}

.view_banner img {
    width: 100%;
    margin-top: 50px;
    position: relative;
    cursor: pointer;
}

.display_gold_cont {
    display: flex;
    align-items: center;
}

.gold_display {
    margin-right: 20px;
    width: 58%;
}

.gold_display1 {
    width: 38%;
}
.ring_main {
    margin-top: 80px;
}
.gold_box {
    margin-bottom: 20px;
}

.display_gold {
    position: relative;
    overflow: hidden;
}

.display_gold_ani{
    position: relative;
    overflow: hidden;
}
.display_gold_ani:hover img {
    transform: scale(1.2);
    transition: 0.3s all ease-in-out;
}
.display_gold_ani img {
    transition: 0.3s all ease-in-out;
}
.category_image_ani{
    overflow: hidden; 
}
.category_image_ani:hover img {
    transform: scale(1.2);
    transition: 0.3s all ease-in-out;
}
.category_image_ani img {
    transition: 0.3s all ease-in-out;
}
.ring_box {
    padding: 30px 20px 0px 20px;
}
.gold_button{
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.gold_btn {
    border: 1px solid rgb(229 229 229 / 25%);
   position: relative;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    padding: 10px 20px;
    width: 225px;
    display: flex;
    border-radius: 4px;
    background: rgb(0 0 0 / 0%) ;
    backdrop-filter: blur(2px);
    align-items: center;
    justify-content: space-between;
}
.gold_btn::before{
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 0%;
    background-color:rgb(8 8 8 / 42%) ;
    border-radius: 4px;
    transition: 0.3s all ease-in-out;
}
.gold_btn:hover::before {
    color: rgb(255, 255, 255);
    width: 100%;
    transition: 0.3s all ease-in-out;
}

.gold_btn:hover {
    color: rgb(255, 255, 255);
}
.ring_main_cont {
    background: rgb(255, 255, 255);
    border-radius: 5px;
}

.ring_box_img img {
    border-radius: 6px;
    width: 100%;
}

/* .main_text {
    color: rgb(22, 34, 91);
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    padding: 10px 0px;
} */

.main_text {
    margin-bottom: 20px;
    color: rgb(224, 150, 39);
    font-family: Varela;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 5px;
    text-transform: uppercase;
}
.tab_content {
    text-align: center;
}
.tab_head {
    color: rgb(191, 191, 191);
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}
.tab_content {
 
    text-align: center;
}
.tab_img {
    margin-bottom: 12px;
}
.tab_text {
    color: rgb(218, 205, 205);
    text-align: center;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.gold_tabs{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.gold_tab_cont {
    margin-top: -65px;
    padding: 100px 0px 30px 0px;
    background-image: url('../../../assets/images/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.read_btn {
    position: relative;
    margin: 20px 20px;
    float: right;
    color: rgb(53, 35, 33);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    padding: 10px 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 5px;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid rgb(228, 228, 228);
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
}

.read_btn::before{
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    color: rgb(0, 0, 0);
    height: 100%;
    width: 0%;
    background-color:rgb(177 127 81 / 63%);
    border-radius: 4px;
    transition: 0.3s all ease-in-out;
}
.read_btn:hover::before {
    color: rgba(0, 0, 0, 0);
    width: 100%;
    transition: 0.3s all ease-in-out;
}

.read_btn:hover {
    color: rgb(53, 35, 33);
}

.read_btn span {
    margin-left: 25px;
}

.ring_cont .col-sm-6 {
    padding: 0px;
}
.category_container {
    display: flex;
    /* padding-bottom: 40px; */
    padding: 0px 20px 20px 20px;
}
.category_container .slick-track {
    margin-top: 20px;
}
.category_container .slick-initialized .slick-slide {
    width: 315px !important;
    margin-right: 6px;
    display: block;
}
.ring_cont {
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
    background: rgb(255, 255, 255);
    padding: 0px 15px;
    /* z-index: 1; */
    position: relative;
}
.ring_img {
    padding: 3px;
}

/* .sub_text {
    color: #333333;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding: 10px 0px;
} */
.sub_text {
    color: rgb(51, 51, 51);
    text-align: justify;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.looknew {
    margin-bottom: 60px;
}

.all_prod {
    position: absolute;
    text-decoration-line: underline;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #FFD27D;
}

@media (min-width:320px) and (max-width:479px) {
    .category_box {
        margin-bottom: 20px;
    }
  
    .gold_heading span {
        font-size: 30px;
    }

    .gold_silver {
        font-size: 12px;
    }

    .gold_name {
        font-size: 14px;
    }

    .main_text {
        font-size: 16px;
    }

    .sub_text {
        font-size: 12px;
    }

    .gold_heading {
        padding: 20px 0px;
    }

    .ring_main {
        margin-top: 30px;
        padding: 40px 15px;
    }
}

@media (min-width:480px) and (max-width:639px) {
    .category_box {
        margin-bottom: 20px;
    }
    .gold_heading span {
        font-size: 30px;
    }

    .gold_silver {
        font-size: 12px;
    }

    .gold_name {
        font-size: 14px;
    }

    .main_text {
        font-size: 16px;
    }

    .sub_text {
        font-size: 12px;
    }

    .gold_heading {
        padding: 20px 0px;
    }

    .ring_main {
        margin-top: 30px;
        padding: 40px 15px;
    }
}

.catregory_cont .slick-track {
    display: flex;
}

.category_box {
    margin-right: 5px;
    border-radius: 10px;
    border-bottom: 4px solid rgb(177, 127, 81);
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
    padding: 8px;
    cursor: pointer;
}

.catregory_cont .slick-slide>div {
    padding: 0 13px;
}

.category_box a {
    float: left;
    width: 100%;
}

.category_image img {
    width: 100%;
    border-radius: 5px;
}

.category_name {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 0px;
    text-transform: uppercase;
    /* font-size: 16px; */
    /* font-weight: 500; */
    /* color: rgb(19 26 125); */
    /* letter-spacing: 0px; */
    line-height: 25px;
    /* text-align: center; */
    padding: 14px 0px 6px 0px;
}
.gold_collect_cont {
    margin-bottom: 20px;
    border-radius: 10px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
    padding: 5px 5px 0px 5px;
}



@media (min-width:768px){

    .menu_header {
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
    }
    .banner_container {
        margin-top: 141px;
    }
    .collection_banner {
        margin-top: 141px;
    }
    .detail_container{
        margin-top: 141px;
    }
    .login_main {
        margin-top: 141px;
    }
}