.detail_container {
  padding: 40px 0px;
}

.detail_left {
  height: 335px;
  text-align: center;
  position: relative;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
}

.product_imageee img {
  height: 650px;
  align-items: end;
  display: flex;
}

.detail_left img {
  max-width: 100%;
}

.enquiry_cont img {
  width: 100%;
}

.detail_name {
  font-family: "Varela";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #362321;
  text-transform: uppercase;
  line-height: 35px;
}
.detail_code {
  font-family: "montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #362321;
  line-height: 17px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.tot_colour {
  color: rgb(54, 35, 33) !important;
}

.detail_rate {
  font-family: "montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #4b4848;
  margin-top: 6px;
}

.detail_rate span {
}

.detail_rate b {
}

.detail_btn {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.detail_btn button {
  margin-right: 10px;
  border: 1px solid rgb(229 229 229 / 25%);
  position: relative;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 10px;
  width: 155px;
  display: flex;
  border-radius: 4px;
  background: rgb(54 35 33);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  align-items: center;
  justify-content: center;
}

.detail_note {
  margin-bottom: 25px;
}

.detail_note li {
  list-style-type: none;
  float: left;
  width: 50%;
  min-height: 28px;
  padding: 12px 10px;
}

.detail_note li {
  font-family: "montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #2d2a2a;
  line-height: 14px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.detail_note li a img {
  margin-right: 10px;
}

.detail_enq {
  font-family: "FuturaNormal";
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  color: rgba(0, 0, 0, 1);
  line-height: 28px;
  text-transform: uppercase;
}

.detail_pin_box {
  background-color: rgba(245, 245, 245, 1);
  padding: 30px;
  margin-top: 20px;
}

.detail_pin_label {
  font-family: "FuturaNormal";
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 17px;
  margin-bottom: 20px;
}

.detail_pin_cont {
  display: flex;
  align-items: center;
}

.detail_pin_cont input {
  font-family: "FuturaNormal";
  font-size: 15px;
  font-weight: 400;
  color: rgba(1, 0, 69, 1);
  line-height: 17px;
  outline: none;
  width: 70%;
  padding: 15px 20px;
  border: 1px solid rgba(1, 0, 69, 1);
  border-radius: 5px;
}

.detail_pin_cont input::placeholder {
  color: rgba(181, 181, 181, 1);
}

.detail_pin_cont button {
  font-family: "FuturaNormal";
  font-size: 15px;
  color: rgba(255, 255, 255, 1);
  line-height: 8px;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 5px;
  padding: 20px;
  border: 1px solid rgb(0, 0, 0);
  height: 50px;
  width: 26%;
  margin-left: 3%;
}

.speification_head {
  font-family: "GFS Didot", serif;
  font-size: 30px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  line-height: 30px;
  text-align: center;
  margin-bottom: 50px;
}

.specification_main {
  margin-top: 90px;
}

.specification_box {
  border-bottom: 1px solid rgb(225 225 225);
  margin-bottom: 45px;
}

.specification_box:last-child {
  margin-bottom: 0px;
}

.specification_head {
  margin-bottom: 45px;
}

.specification_head a {
  font-family: "FuturaNormal";
  font-size: 25px;
  font-weight: 500;
  color: rgba(112, 81, 2, 1);
  line-height: 25px;
  text-decoration: none;
  position: relative;
  float: left;
  width: 100%;
}

.specification_cont li {
  list-style-type: none;
  font-family: "FuturaNormal";
  font-size: 20px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.specification_cont li b {
  font-family: "FuturaMedium";
  font-weight: 500;
}

.specification_head a::before {
  position: absolute;
  content: "\f067";
  font-family: "Font Awesome 5 free";
  font-weight: 600;
  right: 0px;
  top: 0px;
}

.specification_head a[aria-expanded="true"]::before {
  content: "\f068";
}

.related_main {
  margin-top: 50px;
}

.related_head {
  font-size: 20px;
  font-weight: 500;
  color: rgb(53 35 33);
  line-height: 30px;
  text-align: center;
  margin-bottom: 20px;
  font-family: "varela";
  text-transform: uppercase;
}

.related_box {
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
  background-color: rgba(247, 247, 247, 1);
  padding: 18px;
  position: relative;
}
.related_image {
  margin-bottom: 18px;
}

.related_desc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.related_name {
  font-family: "montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: rgb(54 35 33);
  text-transform: uppercase;
  width: 50%;
}

.related_rate {
  font-family: "montserrat";
  font-size: 17px;
  font-weight: 400;
  color: rgb(40 27 25);
  width: 50%;
  text-transform: uppercase;
  text-align: right;
}

.related_rate span {
  font-family: "montserrat";
}

.related_weight {
  font-family: "montserrat";
  font-size: 12px;
  font-weight: 400;
  color: rgb(54 35 33);
  text-transform: uppercase;
  margin-top: 8px;
}

.related_image img {
  width: 100%;
}

.related_cont .row {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: block;
  margin: 0px;
}

.related_cont .col-sm-3 {
  display: inline-block;
  max-width: 22%;
  /* padding: 0px 25px; */
}

.breadcrumbs1 {
  padding: 0px;
}

.price_breakup {
  text-transform: uppercase;
  color: rgb(54 35 33);
  font-family: "Varela", sans-serif;
  font-style: normal;
  font-weight: 500;
  padding: 10px;
  font-size: 16px;
}

.overprice {
  border-bottom: 4px solid rgb(177, 127, 81);
  border-radius: 4px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
}

.related_cont .row {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: block;
  margin: 0;
}

.related_cont .col-sm-3 {
  display: inline-block;
  max-width: 22%;
  /* padding: 0 25px; */
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  background-color: transparent;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: transparent;
}

@media (min-width: 768px) {
  .detail_container .col-sm-6:last-child {
    padding-left: 30px;
  }

  .detail_container .col-sm-6:first-child {
    padding-right: 30px;
  }
}

@media (max-width: 768px) {
  .related_head {
    font-size: 20px;
    margin-bottom: 38px;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .detail_btn {
    display: block;
  }
  .detail_btn button {
    margin-bottom: 10px;
  }
  /* .heart {
    
        top: -12px;
        right: 10px;
    } */
  .related_cont .col-sm-3 {
    display: inline-block;
    max-width: 75%;
    padding: 0 15px;
  }

  .related_name,
  .related_rate {
    font-weight: 400;
    color: #000;
    text-transform: uppercase;
    width: 50%;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .detail_btn {
    display: block;
  }
  .detail_btn button {
    margin-bottom: 10px;
  }
  /* .heart {
    
        top: -12px;
        right: 10px;
    } */
}
@media (min-width: 640px) and (max-width: 767px) {
  .detail_btn {
    display: block;
  }
  .detail_btn button {
    margin-bottom: 10px;
  }
}
