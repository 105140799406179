.header_back{
    background-image: url('../../../assets/images/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.header_menu{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.middlebar {
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    text-decoration: none;
    color: rgb(255,255,255);
    padding: 10px 20px !important;
    display: flex;
    align-items: center;
    margin: 0px;
} 
.middlebar1{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.middlebar li {
    list-style-type: none;
    padding: 0px 20px;
    position: relative;
}
.middlebar li.filler::before {
    display: none;
}
.middlebar li::before {
    content: '';
    height: 10px;
    width: 1px;
    position: absolute;
    background-color: rgb(255,255,255);
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
}
.filler {
    flex-grow: 1;
}
.middlebar span {
    margin-right: 10px;
}
/* .middlebar li:first-child::before, .middlebar li:last-child::before {
display: none;
} */
.middlebar li:first-child::before
{
    display: none;
}
.middlebar li a {
    color: rgb(255 190 55);
}
.middlebar .overlay {
    display: none;
}




@media (max-width: 767px) {

.middlebar li {
    display: inline-block;
}
.middlebar li.filler {
    display: none;
}
}
@media (min-width: 320px) and (max-width: 479px) {
    .logo img {
        width: 75%;
        cursor: pointer;
    }
    ul.cart_section {
        width: 40%;
        margin-bottom: 0;
    }
    .cart_section li:first-child {
        margin-right: 12px;
    }
    .cart_section li:last-child {
        margin-left: 12px;
    }
}