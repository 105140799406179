
.about_img img{
    width: 100%;
}
.about_main{
    padding: 10px;
}
.about_head {
    font-size: 22px;
    padding: 10px 0px;
}
.abt_container {
    padding: 25px 0px;
}
.about_head {
    font-family: 'Rochester', cursive;
    color: rgb(192, 137, 32);
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 50px;
    margin-bottom: 10px;
}
.about_body {
    font-family: 'Montserrat', sans-serif;
    color: rgb(51, 51, 51);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
    letter-spacing: 0.5px;
}
.about_img img {
    border-radius: 3px;
    box-shadow: 8px 8px 8px rgb(36 36 36 / 35%);
}
span.about_body img {
    border-radius: 6px;
    float: left;
    margin-right: 30px;
}
@media (min-width:320px) and (max-width:479px){
    .about_body img {
        margin-bottom: 20px;
    }
    .abt_container{
        padding: 0;
    }
    span.about_body img {
        width: 100%;
        float: left;
        margin-right: 30px;
    }
    .about_main{
    padding: 10px;
}
.contact_main {
    padding: 40px 0px !important;
}
.map_show{
    padding: 4px;
}
.contactMain {
    padding: 18px 0px;
}
}
@media (min-width:480px) and (max-width:639px){
    .about_body img {
        margin-bottom: 20px;
    }
    .abt_container{
        padding: 0;
    }
    span.about_body img {
        width: 100%;
        float: left;
        margin-right: 30px;
    }
}
@media (min-width:640px) and (max-width:767px){
    .about_body img {
        margin-bottom: 20px;
    } 
}