.privacy {
    margin-top: 145px;
    padding: 40px;
    min-height: calc(100vh - 442px);
    word-break: break-word;
    text-align: justify;
}
.privacyPolicy {
    font-size: 24px;
    color: #400000;
    margin-bottom: 20px;
}
.privacy_sub{
    font-size: 20px;
    color: #400000;
    margin-bottom: 5px;
}
.privacy_li{
    font-size: 15px;
    color: #400000;
}
@media (max-width: 767px) {

    .privacy{
        margin-top: 0px !important;
        padding: 40px 20px;
    }
}