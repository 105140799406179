.thankyou_container {
  padding: 0px 20px;
}

.thankyou_image {
  text-align: center;
}

.thankyou_desk {
  font-family: "FuturaNormal";
  font-size: 25px;
  font-weight: 400;
  color: rgba(71, 71, 71, 1);
  text-align: center;
  letter-spacing: 0px;
  line-height: 40px;
}
.thankyou_box {
  padding-bottom: 40px;
  text-align: center;
  font-size: 26px;
  padding: 40px;
  color: rgb(22, 34, 91) !important;
}

.thankyou_image {
  padding: 150px 0px 35px 0px;
}

@media (max-width: 767px) {
  .thankyou_image {
    padding: 10px 0px !important;
  }
  .thankyou_box {
    padding: 20px;
  }

  .thankyou_image img {
    width: 100%;
  }
}
