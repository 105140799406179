.contact_main{
    padding: 40px 0px;
}
.map_show iframe {
    width: 100%;
}
.submit_btn button {
    font-family: 'montserrat';
    border-radius: 4px;
    width: 100%;
    background-color: rgb(51 33 31);
    padding: 10px;
    color: white;
    margin-top: 10px;
    border: none;
}
.contactMain {
    border-radius: 5px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
    padding: 15px;
}

.address_head {
    font-family: 'montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    text-transform: uppercase;
    color: rgb(180 126 85);
    border-bottom: 1px solid rgb(180 126 85);
    padding: 15px 0px;
    margin-bottom: 25px;
}
.address_box li {
    font-family: 'montserrat';
    list-style-type: none;
    margin-bottom: 15px;
    font-size: 15px;
    position: relative;
    padding-left: 30px;
}
.address_box li::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    position: absolute;
    left: 0px;
    top: 0px;
    color: rgb(180 126 85);
}
.address_box li:nth-child(1):before {
    content: '\f879';
}
.address_box li:nth-child(2):before {
    content: '\f0e0';
}
.address_box li:nth-child(3):before {
    content: '\f3c5';
}