.details {
    color: rgb(52 34 32);
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    padding: 18px 0px 4px;
}

.welcome_reg {
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    text-transform: uppercase;
    color: rgb(177, 127, 81);
    border-bottom: 1px solid rgb(177, 127, 81);

}

.login_form_reg {
    padding: 34px;
}

.mob_numbers {
    margin-top: 26px;
}

.image_sub1 {
    position: relative;
}

.image_sub1::before {
    content: '';
    height: 234px;
    width: 1px;
    background-color: rgb(177, 127, 81);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 70px;
}

.image_sub1::after {
    content: '';
    height: 234px;
    width: 1px;
    background-color: rgb(177, 127, 81);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 70px;
}

.enquiry_head {
    font-family: "FuturaNormal";
    font-size: 25px;
    font-weight: 400;
    color: rgb(52 34 32);
    margin-bottom: 22px;
    text-align: center;
}

.enquiry_cont .form-group input {
    font-family: "FuturaNormal";
    font-size: 16px;
    font-weight: 400;
    color: rgb(52 34 32);
    line-height: 23px;
    border: 1px solid #767676;
    border-radius: 5px;
    outline: none;
    float: left;
    width: 100%;
    padding: 16px 20px;
}

.mode_btn {
    text-align: center;
    margin-top: 35px;
}

.mode_btn button {
    font-family: "FuturaNormal";
    font-size: 20px;
    color: #fff;
    line-height: 13px;
    background-color: rgb(52 34 32);
    border: none;
    border-radius: 4px;
    padding: 18px 70px;
}

.modalPop {
    position: fixed;
    background-color: rgb(105 93 93 / 60%);
    z-index: 1050;
    width: 100%;
    height: 100vh;
    transition: all .5s ease-in-out;
    top: 0;
    left: 0;
    overflow: auto;
}

@media (max-width: 767px) {

    .image_sub1:after,
    .image_sub1:before {
        display: none;
    }

    .image_sub1 {
        display: none;
    }

    .details {
        font-size: 12px;
    }
}