.myaccount {
    margin-top: 135px;
    padding: 40px;
}
.submit_btn button {
    font-family: 'montserrat';
    border-radius: 4px;
    width: 50%;
    background-color: rgb(51 33 31);
    padding: 10px;
    color: white;
    margin-top: 10px;
    border: none;
}
.mycontact {
    padding: 38px 0px;
}
.submit_btn {
    text-align: center;
}
.mob{
    opacity: 0.5;

}
@media (max-width:768px){
    .myaccount {
    padding: 40px 20px;
}
.mycontact {
    padding: 26px 0px;
}
}