.footer_main {
    background-image: url('../../../assets/images/bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 25px 0px;
}
.footer_img {
    text-align: center;
    margin-bottom: 10px;
}

.gold_mart {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: rgb(255, 190, 55);
    text-transform: uppercase;
    text-align: center;
}

.mart_subtext {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: #FFBE37;
    text-align: center;
    padding: 6px 0px;
}

.menu_sub li {
    list-style: none;
    display: inline-block;
    margin-left: 15px;
    color: #FFFFFF;
    font-size: 12px;
    cursor: pointer;
}

.menu_sub li:first-child {
    margin-left: 0px;
}

.menu_display {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    border-bottom: 1px solid rgb(255, 255, 255);
    float: left;
    padding: 12px;
        margin-bottom: 14px;
}

.menu_sub {
    padding: 15px 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu_sub1 {
    padding-top: 15px !important;
    display: flex;
    align-items: center;
}

.menu_sub1 li {
    list-style: none;
    display: inline-block;
    font-size: 12px;
    margin-left: 15px;
    color: rgb(255, 255, 255);
    float: right;
    cursor: pointer;
    word-break: break-word;
}
.menu_sub1 li a {
    color: rgb(255,255,255);
}
.cat_head {
    margin-bottom: 20px;
    color: rgb(255, 218, 185);
    font-family: Montserrat;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    text-transform: uppercase;
}
.footer_email {
    color: rgb(255, 255, 255);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    text-transform: lowercase;
}
.footer_add {
    margin-bottom: 15px;
    color: rgb(255, 255, 255);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    text-transform: uppercase;
}
.footer_address {
    width: 40%;
}
.foot_category:last-child {
    margin-top: 45px;
}
.foot_category {
    width: 25%;
}
.footer_add span {
    margin-right: 15px;
}
.imp_links {
    width: 25%;
}
.footer_email span {
    margin-right: 15px;
}
.footer_logo {
    margin-bottom: 15px;
}
.cat_list {
    padding-left: 30px;
}

.cate_list li a{
    color: rgb(255, 255, 255);
}
.cat_list li {
    cursor: pointer;
    position: relative;
    padding: 5px 0px;
    color: rgb(255, 255, 255);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    list-style-type: none;
}
.cat_list li::before {
    transform: rotate(90deg);
    content: '';
    position: absolute;
    width: 1px;
    height: 10px;
    top: 12px;
    left: -20px;
    background: rgba(177, 127, 81, 1);
}
.copy_right_cont{
    color: rgb(44, 27, 25);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer_media span {
    margin-right: 10px;
}
.copy_right_main {
    padding: 5px 0px;
    background: rgb(177, 127, 81);
}
.footer_box {
    display: flex;
    padding: 0px;
}

@media (min-width:320px) and (max-width:767px) { 
    .footer_box {
        display: block;
        padding: 0px;
    }
    .footer_address {
        margin-bottom: 20px;
        width: 100%;
    }
    .footer_logo {
        text-align: center;
        margin-bottom: 15px;
    }
    .footer_add {
        margin-bottom: 15px;
        font-size: 13px;
    }
    .footer_email {
        font-size: 14px;
    }
    .footer_email span {
        margin-right: 10px;
    }
    .foot_category {
        width: 100%;
    }
    .cat_head{
        margin-bottom: 0px;
    }
    .imp_links{
        width: 100%;
    }
    .copy_right_cont {
        font-size: 10px;
    }
    .menu_sub {
        float: left;
        width: 100%;
    }

    .menu_sub1 li {
        float: left;
        width: 50%;
        margin: 0px;
        text-align: left !important;
        margin-top: 6px;
    }

    .menu_sub1 {
        display: block;
    }
}

.whatsapp_icon {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 999999;
    box-shadow: 0 0 8px rgb(0 0 0 / 57%);
    border-radius: 50%;
}