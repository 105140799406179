.privacy{
    padding: 40px;
}
.privacyPolicy {
    font-size: 24px;
    color: #400000;
    margin-bottom: 20px;
}

.privacy_list{
    list-style-type: none;
}
.privacy_sub_list{
    margin-bottom: 20px;
    padding-left: 20px;
}