@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Varela&display=swap");

.flt {
  float: left;
  width: 100%;
}

.body {
  font-family: "Montserrat", sans-serif;
  /* font-family: "Varela", sans-serif; */
}

ul {
  padding: 0px;
  margin: 0px;
}

.pagination li {
  margin-left: 10px;
}

.pagination {
  justify-content: flex-end;
  margin-top: 40px !important;
}

.pagination li a {
  border-radius: 20%;
  width: 25px;
  height: 25px;
  line-height: 25px;
  display: block;
  border: 1px solid rgb(0, 0, 0);
  text-align: center;
  font-size: 11px;
}

.pagination li.active a {
  background-color: rgb(46, 30, 28);
  color: rgb(255 255 255) !important;
}

.cartpg {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  text-align: center;
  background-color: rgb(255 255 255 / 90%);
  z-index: 9;
}

.cartpg img {
  position: fixed;
  left: calc(50% - 50px);
  top: calc(50% - 60px);
  animation: zoom-in-zoom-out 1s ease infinite;
}

.profile_container {
  padding: 245px 270px !important;
}

.wish_box {
  background-color: rgba(247, 247, 247, 1);
  position: relative;
  margin-bottom: 30px;
}

.wish_close {
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
  padding: 0px;
  border: none;
  outline: none;
  background-color: transparent;
  /* z-index: 1; */
}

.wish_image {
  padding: 12px;
  cursor: pointer;
}

.wish_image img {
  width: 100%;
}

.wish_desc {
  font-family: "FuturaNormal";
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  padding: 0px 12px;
}

.wish_rate {
  font-family: "FuturaMedium";
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  line-height: 12px;
  text-align: center;
}

.wish_btn button {
  font-family: "FuturaMedium";
  font-size: 16px;
  color: rgba(235, 60, 60, 1);
  letter-spacing: 0px;
  line-height: 12px;
  border: none;
  float: left;
  width: 100%;
  background-color: transparent;
  padding: 20px;
  border-top: 1px solid rgb(228 228 228);
  margin-top: 15px;
}

.wish_rate b {
  font-family: "GFS Didot", serif;
  font-weight: 300;
}

#searchModal .modal-body {
  padding: 0px;
}

#searchModal .modal-body span {
  float: left;
  width: 100%;
  position: relative;
}

#searchModal .modal-body span::before {
  content: "";

  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

#searchModal .modal-body input {
  border: none;
  border-radius: 4px;
  font-family: "varela";
  font-size: 15px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  line-height: 17px;
  float: left;
  width: 100%;
  outline: none;
  padding: 20px 20px 20px 40px;
}

#searchModal .modal-body input::placeholder {
  color: rgba(165, 163, 163, 1);
}

#searchModal .modal-footer {
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0.5rem;
  border-top: 1px solid rgb(235 235 235);
}

.search_btn {
  font-size: 13px;
  letter-spacing: 1px;
  color: rgb(255 255 255);
  background-color: rgb(77 63 61);
  border: 1px solid rgb(77 63 61);
  transition: 0.5s all ease;
  outline: none;
  padding: 10px;
  text-decoration: none;
  font-family: "varela";
  width: 50%;
  text-transform: uppercase;
}

.close_btn {
  font-size: 13px;
  letter-spacing: 1px;
  color: rgb(77 63 61);
  background-color: rgb(255 255 255);
  border: 1px solid rgb(77 63 61);
  transition: 0.5s all ease;
  outline: none;
  padding: 10px;
  text-decoration: none;

  width: 50%;
  text-transform: uppercase;
}

.detail_more {
  height: 100px;
  margin-top: 5px;
  gap: 5px;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-between; */
}

.web_action_btn {
  cursor: pointer;
}

.web_action_btn span a {
  color: rgb(50, 32, 28);
  text-decoration: none;
}

.empty_order_image {
  text-align: center;
}

.empty_order_head {
  font-family: "Montserrat";
  font-size: 30px;
  font-weight: 500;
  color: rgba(151, 153, 154, 1);
  text-align: center;
  margin-top: 20px;
}

.detail_more li {
  float: left;
  width: 100px;
  list-style-type: none;
  display: flex;
  border: 1px solid rgb(224 224 224);
  cursor: pointer;
}

.detail_more li img {
  width: 100%;
}

.container-main {
  padding: 0px 120px;
}
p {
  font-size: 14px;
}

.termsCondList li {
  font-size: 14px;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(0.8, 0.8);
  }

  100% {
    transform: scale(1, 1);
  }
}

/* Media Query */

@media (max-width: 767px) {
  .myaccount {
    margin-top: 0px !important;
  }

  .container-main {
    padding: 0px 20px !important;
  }
  .product_imageee img {
    height: auto !important;
  }
  .detail_more {
    height: 65px !important;
  }
  .detail_more li {
    width: 65px !important;
  }

  .hide_mobile {
    display: none !important;
  }

  .empty_order_image img {
    width: 100%;
  }

  .profile_container {
    padding: 45px 25px !important;
  }

  .web_action_btn br {
    display: none;
  }

  .wish_btn button {
    font-size: 14px;
    padding: 20px 10px;
  }
}

@media (min-width: 360px) and (max-width: 479px) {
  .detail_right {
    margin-top: 170px !important;
  }
}

@media (min-width: 480px) and (max-width: 575px) {
  .detail_right {
    margin-top: 270px !important;
  }
}
@media (min-width: 768px) {
  .hide_desktop {
    display: none !important;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .detail_right {
    margin-top: 50px;
  }

  .category_container {
    display: block !important;
  }

  .main_cart {
    padding: 20px !important;
    margin-top: 0px !important;
  }

  .logo img {
    width: 75%;
    cursor: pointer;
  }

  .main_order {
    padding: 20px !important;
    margin-top: 0px !important;
  }

  ul.cart_section {
    width: 40%;
    margin-bottom: 0;
  }

  .cart_section li:first-child {
    margin-right: 12px;
  }

  .cart_section li:last-child {
    margin-left: 12px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .detail_right {
    margin-top: 30px;
  }

  .category_container {
    display: block !important;
  }

  .main_cart {
    padding: 20px !important;
    margin-top: 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .profile_container {
    padding: 165px 50px !important;
  }
  .wish_btn button {
    font-size: 15px;
    padding: 20px 10px;
  }
  .wish_close {
    right: 15px;
    top: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .profile_container {
    padding: 245px 120px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .wish_btn button {
    font-size: 15px;
    padding: 20px 10px;
  }

  .wish_close {
    right: 12px;
    top: 12px;
  }
}
