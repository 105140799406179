@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600&family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Varela&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rochester&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.invalid-feedback{
  display: block !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.alert {
    position: fixed !important;
    bottom: 10px;
    float: right;
    right: 10px;
    z-index: 9;
        box-shadow: 0px 0px 8px 0px;
}

.flt {
  float: left;
  width: 100%;
}

.body {
  font-family: "Montserrat", sans-serif;
  /* font-family: "Varela", sans-serif; */
}

ul {
  padding: 0px;
  margin: 0px;
}

.pagination li {
  margin-left: 10px;
}

.pagination {
  justify-content: flex-end;
  margin-top: 40px !important;
}

.pagination li a {
  border-radius: 20%;
  width: 25px;
  height: 25px;
  line-height: 25px;
  display: block;
  border: 1px solid rgb(0, 0, 0);
  text-align: center;
  font-size: 11px;
}

.pagination li.active a {
  background-color: rgb(46, 30, 28);
  color: rgb(255 255 255) !important;
}

.cartpg {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  text-align: center;
  background-color: rgb(255 255 255 / 90%);
  z-index: 9;
}

.cartpg img {
  position: fixed;
  left: calc(50% - 50px);
  top: calc(50% - 60px);
  -webkit-animation: zoom-in-zoom-out 1s ease infinite;
          animation: zoom-in-zoom-out 1s ease infinite;
}

.profile_container {
  padding: 245px 270px !important;
}

.wish_box {
  background-color: rgba(247, 247, 247, 1);
  position: relative;
  margin-bottom: 30px;
}

.wish_close {
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
  padding: 0px;
  border: none;
  outline: none;
  background-color: transparent;
  /* z-index: 1; */
}

.wish_image {
  padding: 12px;
  cursor: pointer;
}

.wish_image img {
  width: 100%;
}

.wish_desc {
  font-family: "FuturaNormal";
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  padding: 0px 12px;
}

.wish_rate {
  font-family: "FuturaMedium";
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0px;
  line-height: 12px;
  text-align: center;
}

.wish_btn button {
  font-family: "FuturaMedium";
  font-size: 16px;
  color: rgba(235, 60, 60, 1);
  letter-spacing: 0px;
  line-height: 12px;
  border: none;
  float: left;
  width: 100%;
  background-color: transparent;
  padding: 20px;
  border-top: 1px solid rgb(228 228 228);
  margin-top: 15px;
}

.wish_rate b {
  font-family: "GFS Didot", serif;
  font-weight: 300;
}

#searchModal .modal-body {
  padding: 0px;
}

#searchModal .modal-body span {
  float: left;
  width: 100%;
  position: relative;
}

#searchModal .modal-body span::before {
  content: "";

  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

#searchModal .modal-body input {
  border: none;
  border-radius: 4px;
  font-family: "varela";
  font-size: 15px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  line-height: 17px;
  float: left;
  width: 100%;
  outline: none;
  padding: 20px 20px 20px 40px;
}

#searchModal .modal-body input::-webkit-input-placeholder {
  color: rgba(165, 163, 163, 1);
}

#searchModal .modal-body input::placeholder {
  color: rgba(165, 163, 163, 1);
}

#searchModal .modal-footer {
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0.5rem;
  border-top: 1px solid rgb(235 235 235);
}

.search_btn {
  font-size: 13px;
  letter-spacing: 1px;
  color: rgb(255 255 255);
  background-color: rgb(77 63 61);
  border: 1px solid rgb(77 63 61);
  transition: 0.5s all ease;
  outline: none;
  padding: 10px;
  text-decoration: none;
  font-family: "varela";
  width: 50%;
  text-transform: uppercase;
}

.close_btn {
  font-size: 13px;
  letter-spacing: 1px;
  color: rgb(77 63 61);
  background-color: rgb(255 255 255);
  border: 1px solid rgb(77 63 61);
  transition: 0.5s all ease;
  outline: none;
  padding: 10px;
  text-decoration: none;

  width: 50%;
  text-transform: uppercase;
}

.detail_more {
  height: 100px;
  margin-top: 5px;
  grid-gap: 5px;
  gap: 5px;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-between; */
}

.web_action_btn {
  cursor: pointer;
}

.web_action_btn span a {
  color: rgb(50, 32, 28);
  text-decoration: none;
}

.empty_order_image {
  text-align: center;
}

.empty_order_head {
  font-family: "Montserrat";
  font-size: 30px;
  font-weight: 500;
  color: rgba(151, 153, 154, 1);
  text-align: center;
  margin-top: 20px;
}

.detail_more li {
  float: left;
  width: 100px;
  list-style-type: none;
  display: flex;
  border: 1px solid rgb(224 224 224);
  cursor: pointer;
}

.detail_more li img {
  width: 100%;
}

.container-main {
  padding: 0px 120px;
}
p {
  font-size: 14px;
}

.termsCondList li {
  font-size: 14px;
}

@-webkit-keyframes zoom-in-zoom-out {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8);
  }

  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8);
  }

  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

/* Media Query */

@media (max-width: 767px) {
  .myaccount {
    margin-top: 0px !important;
  }

  .container-main {
    padding: 0px 20px !important;
  }
  .product_imageee img {
    height: auto !important;
  }
  .detail_more {
    height: 65px !important;
  }
  .detail_more li {
    width: 65px !important;
  }

  .hide_mobile {
    display: none !important;
  }

  .empty_order_image img {
    width: 100%;
  }

  .profile_container {
    padding: 45px 25px !important;
  }

  .web_action_btn br {
    display: none;
  }

  .wish_btn button {
    font-size: 14px;
    padding: 20px 10px;
  }
}

@media (min-width: 360px) and (max-width: 479px) {
  .detail_right {
    margin-top: 170px !important;
  }
}

@media (min-width: 480px) and (max-width: 575px) {
  .detail_right {
    margin-top: 270px !important;
  }
}
@media (min-width: 768px) {
  .hide_desktop {
    display: none !important;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .detail_right {
    margin-top: 50px;
  }

  .category_container {
    display: block !important;
  }

  .main_cart {
    padding: 20px !important;
    margin-top: 0px !important;
  }

  .logo img {
    width: 75%;
    cursor: pointer;
  }

  .main_order {
    padding: 20px !important;
    margin-top: 0px !important;
  }

  ul.cart_section {
    width: 40%;
    margin-bottom: 0;
  }

  .cart_section li:first-child {
    margin-right: 12px;
  }

  .cart_section li:last-child {
    margin-left: 12px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .detail_right {
    margin-top: 30px;
  }

  .category_container {
    display: block !important;
  }

  .main_cart {
    padding: 20px !important;
    margin-top: 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .profile_container {
    padding: 165px 50px !important;
  }
  .wish_btn button {
    font-size: 15px;
    padding: 20px 10px;
  }
  .wish_close {
    right: 15px;
    top: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .profile_container {
    padding: 245px 120px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1365px) {
  .wish_btn button {
    font-size: 15px;
    padding: 20px 10px;
  }

  .wish_close {
    right: 12px;
    top: 12px;
  }
}

.footer_main {
    background-image: url(/static/media/bg.9528cb4f.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 25px 0px;
}
.footer_img {
    text-align: center;
    margin-bottom: 10px;
}

.gold_mart {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: rgb(255, 190, 55);
    text-transform: uppercase;
    text-align: center;
}

.mart_subtext {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: #FFBE37;
    text-align: center;
    padding: 6px 0px;
}

.menu_sub li {
    list-style: none;
    display: inline-block;
    margin-left: 15px;
    color: #FFFFFF;
    font-size: 12px;
    cursor: pointer;
}

.menu_sub li:first-child {
    margin-left: 0px;
}

.menu_display {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    border-bottom: 1px solid rgb(255, 255, 255);
    float: left;
    padding: 12px;
        margin-bottom: 14px;
}

.menu_sub {
    padding: 15px 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu_sub1 {
    padding-top: 15px !important;
    display: flex;
    align-items: center;
}

.menu_sub1 li {
    list-style: none;
    display: inline-block;
    font-size: 12px;
    margin-left: 15px;
    color: rgb(255, 255, 255);
    float: right;
    cursor: pointer;
    word-break: break-word;
}
.menu_sub1 li a {
    color: rgb(255,255,255);
}
.cat_head {
    margin-bottom: 20px;
    color: rgb(255, 218, 185);
    font-family: Montserrat;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    text-transform: uppercase;
}
.footer_email {
    color: rgb(255, 255, 255);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    text-transform: lowercase;
}
.footer_add {
    margin-bottom: 15px;
    color: rgb(255, 255, 255);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    text-transform: uppercase;
}
.footer_address {
    width: 40%;
}
.foot_category:last-child {
    margin-top: 45px;
}
.foot_category {
    width: 25%;
}
.footer_add span {
    margin-right: 15px;
}
.imp_links {
    width: 25%;
}
.footer_email span {
    margin-right: 15px;
}
.footer_logo {
    margin-bottom: 15px;
}
.cat_list {
    padding-left: 30px;
}

.cate_list li a{
    color: rgb(255, 255, 255);
}
.cat_list li {
    cursor: pointer;
    position: relative;
    padding: 5px 0px;
    color: rgb(255, 255, 255);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    list-style-type: none;
}
.cat_list li::before {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    content: '';
    position: absolute;
    width: 1px;
    height: 10px;
    top: 12px;
    left: -20px;
    background: rgba(177, 127, 81, 1);
}
.copy_right_cont{
    color: rgb(44, 27, 25);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer_media span {
    margin-right: 10px;
}
.copy_right_main {
    padding: 5px 0px;
    background: rgb(177, 127, 81);
}
.footer_box {
    display: flex;
    padding: 0px;
}

@media (min-width:320px) and (max-width:767px) { 
    .footer_box {
        display: block;
        padding: 0px;
    }
    .footer_address {
        margin-bottom: 20px;
        width: 100%;
    }
    .footer_logo {
        text-align: center;
        margin-bottom: 15px;
    }
    .footer_add {
        margin-bottom: 15px;
        font-size: 13px;
    }
    .footer_email {
        font-size: 14px;
    }
    .footer_email span {
        margin-right: 10px;
    }
    .foot_category {
        width: 100%;
    }
    .cat_head{
        margin-bottom: 0px;
    }
    .imp_links{
        width: 100%;
    }
    .copy_right_cont {
        font-size: 10px;
    }
    .menu_sub {
        float: left;
        width: 100%;
    }

    .menu_sub1 li {
        float: left;
        width: 50%;
        margin: 0px;
        text-align: left !important;
        margin-top: 6px;
    }

    .menu_sub1 {
        display: block;
    }
}

.whatsapp_icon {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 999999;
    box-shadow: 0 0 8px rgb(0 0 0 / 57%);
    border-radius: 50%;
}
.header_back{
    background-image: url(/static/media/bg.9528cb4f.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.header_menu{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.middlebar {
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    text-decoration: none;
    color: rgb(255,255,255);
    padding: 10px 20px !important;
    display: flex;
    align-items: center;
    margin: 0px;
} 
.middlebar1{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.middlebar li {
    list-style-type: none;
    padding: 0px 20px;
    position: relative;
}
.middlebar li.filler::before {
    display: none;
}
.middlebar li::before {
    content: '';
    height: 10px;
    width: 1px;
    position: absolute;
    background-color: rgb(255,255,255);
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 0px;
}
.filler {
    flex-grow: 1;
}
.middlebar span {
    margin-right: 10px;
}
/* .middlebar li:first-child::before, .middlebar li:last-child::before {
display: none;
} */
.middlebar li:first-child::before
{
    display: none;
}
.middlebar li a {
    color: rgb(255 190 55);
}
.middlebar .overlay {
    display: none;
}




@media (max-width: 767px) {

.middlebar li {
    display: inline-block;
}
.middlebar li.filler {
    display: none;
}
}
@media (min-width: 320px) and (max-width: 479px) {
    .logo img {
        width: 75%;
        cursor: pointer;
    }
    ul.cart_section {
        width: 40%;
        margin-bottom: 0;
    }
    .cart_section li:first-child {
        margin-right: 12px;
    }
    .cart_section li:last-child {
        margin-left: 12px;
    }
}
.menu_image {
  width: 100%;
  text-align: center;
}
.web_action_wish span b {
  font-weight: 400 !important;
}
.cart_section li {
  margin-right: 12px;
  list-style: none;
  display: inline-block;
  position: relative;
  text-align: center;
}

.cart_section li span {
  color: rgb(50, 32, 28);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.cart_section1 li b {
  position: absolute;
  right: -7px;
  top: -3px;
  background-color: rgb(223 159 26);
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  font-size: 12px;
  /* color: rgb(255, 255, 255); */
  font-weight: 600;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 50%);
}

.cart_section li:last-child {
  margin-left: 20px;
}

.cart_section li i {
  color: rgb(255, 255, 255);
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
  font-size: 14px;
}

.cart_section i img {
  border-radius: 5px;
}

.cart_section_1 li {
  margin-right: 15px;
  list-style: none;
  display: inline-block;
  position: relative;
  text-align: center;
}

.cart_section_1 li span {
  color: rgb(50, 32, 28);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.cart_section1 li b {
  position: absolute;
  right: -7px;
  top: -3px;
  background-color: rgb(223 159 26);
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  font-size: 12px;
  /* color: rgb(255, 255, 255); */
  font-weight: 600;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 50%);
}

/* .cart_section_1 li:last-child {
    margin-left: 20px;
} */

.cart_section_1 li i {
  color: rgb(255, 255, 255);
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
  font-size: 14px;
}

.cart_section_1 i img {
  border-radius: 5px;
}

.cart_section_1 li:hover .submenu {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  transition: 0.5s all ease-in-out;
  opacity: 1 !important;
  visibility: visible !important;
  cursor: pointer;
}

ul.cart_section_1 {
  margin-bottom: 0px;
  width: 40%;
}

.logo {
  text-align: center;
  width: 20%;
}

.align-center {
  display: inline-block;
  text-align: center;
}

.nav-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: normal;
}

/* .menuH li:hover{
    background:  rgb(54 35 33);
    color: rgb(255, 255, 255);
} */
.menuH li {
  padding: 6x 10px !important;
  color: rgb(50, 32, 28);
  font-family: "varela";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.857px;
  text-transform: uppercase;
  list-style: none;
  display: inline-block;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.menuH li:hover {
  transition: 0.3s all ease-in;
  border-bottom: 2px solid rgb(44, 29, 27);
}

ul.cart_section {
  width: 40%;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.menu_main {
  z-index: 1;
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  box-shadow: 0px 0px 5px rgb(64 0 0 / 20%);
}

.menu_main .row {
  align-items: center;
}

.header_menu_list {
  background: rgba(255, 236, 231, 1);
  text-align: center;
  border-bottom: 2px solid rgba(177, 127, 81, 1);
}

.header_menu_list .menuH {
  margin: 0;
  padding: 10px;
}

.logo img {
  cursor: pointer;
}

/* .menuH_container{
    display: none;
} */
/* .menubar{
    display: none;
} */

.header_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
}

.header_main .menuH {
  margin-bottom: 0px;
}

.menuH li {
  list-style: none;
  display: inline-block;
  margin: 0px 12px;
}

.submenu {
  position: absolute;
  left: -102px;
  top: 50px;
  width: 140px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 6px rgb(68 68 68 / 40%);
  border-radius: 2px;
  border-top: 3px solid rgb(54 35 33);
  -webkit-transform: translateY(70px);
          transform: translateY(70px);
  opacity: 0;
  visibility: hidden;
  transition: 0.5s all ease-in-out;
  z-index: 1;
}

.cart_section li:hover .submenu {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  transition: 0.5s all ease-in-out;
  opacity: 1 !important;
  visibility: visible !important;
  cursor: pointer;
}

/* .cart_section li:first-child {
    margin-right: 20px;
} */

.submenu li {
  float: left;
  width: 100%;
  text-align: left;
  margin: 0px !important;
  padding: 10px 15px;
  font-size: 13px;
  color: rgb(51, 51, 51);
  border-bottom: 1px solid rgb(229 229 229);
  font-family: "Montserrat";
}

.submenu li:last-child {
  border-bottom: none !important;
}

.dailyrate {
  left: 0px;
  width: 180px !important;
}

.dailyrate li {
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .header_menu_list .menuH {
    margin: 0;
    padding: 5px;
  }

  .sidemenu_list ul {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .sidemenu_list li {
    padding: 8px !important;
    width: 40px !important;
    height: 40px !important;
    background: rgb(255, 255, 255) !important;
    margin-right: 4px !important;
    border-radius: 4px !important;
  }

  .menuH_container {
    display: block;
  }

  .mobileMenu_btn {
    float: right;
    padding: 10px;
    /* background-color: #40000030; */
    background-color: #40000024;
    width: 188px;
  }

  .mobileMenu_btn span {
    float: right;
  }

  .header {
    float: right;
    text-align: left;
    width: 188px;
    background-color: #40000024;
    padding: 10px 16px;
  }

  .menubar {
    position: absolute;
  }

  /* #mobileMenu {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
    background-color: rgb(255 255 255);
    box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 24%);
} */

  .menuH {
    position: fixed;
    right: -25px;
    top: 0px;
    background-color: rgb(51 33 31);
    width: 0;
    height: 100vh;
    z-index: 1;
    transition: 0.5s all ease-in-out;
    box-shadow: 0px 0px 5px rgb(64 0 0 / 20%);
    /* border-top: 4px solid rgb(47 30 29); */
  }

  .toggle_bar {
    width: 25px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0px;
    margin-right: 20px;
  }

  .toggle_bar span {
    float: left;
    width: 100%;
    height: 2px;
    background-color: rgb(51 33 31);
    transition: 0.5s all ease-in-out;
  }

  .fixed_menu {
    right: 0px;
    width: 260px;
    transition: 0.5s all ease-in-out;
  }

  .menuH li {
    /* background: rgb(50, 32, 28); */
    font-family: "varela";
    color: rgb(255, 255, 255);
    float: left;
    width: 100%;
    padding: 10px 20px;
    margin: 0px;
    border-bottom: 1px solid rgb(242 242 242);
  }

  /* .menu_main {
        height: 84px;
        border-bottom: 1px solid rgb(22 34 91);
    } */

  .middlebar {
    height: 36px;
  }

  .toggle_rotate span:nth-child(1) {
    -webkit-transform: rotate(45deg) translate(3px, 9px);
            transform: rotate(45deg) translate(3px, 9px);
    transition: 0.5s all ease-in-out;
  }

  .toggle_rotate span:nth-child(2) {
    display: none;
    transition: 0.5s all ease-in-out;
  }

  .toggle_rotate span:nth-child(3) {
    -webkit-transform: rotate(-45deg) translate(3px, -9px);
            transform: rotate(-45deg) translate(3px, -9px);
    transition: 0.5s all ease-in-out;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  ul.cart_section_1 {
    margin-bottom: 0px;
    width: 40%;
  }

  .logo {
    text-align: center;
    width: 20%;
  }

  ul.cart_section {
    width: 40%;
  }

  .middlebar li {
    padding: 0px 5px;
    font-size: 8px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  ul.cart_section_1 {
    margin-bottom: 0px;
    width: 40%;
  }

  .logo {
    text-align: center;
    width: 20%;
  }

  ul.cart_section {
    width: 40%;
  }

  .middlebar li {
    padding: 0px 10px;
    font-size: 10px;
  }
}

@media (min-width: 1200px) and (max-width: 1365px) {
  ul.cart_section_1 {
    margin-bottom: 0px;
    width: 40%;
  }

  .logo {
    text-align: center;
    width: 20%;
  }

  ul.cart_section {
    width: 40%;
  }
}

/* .sidemenu_list i {
    margin-right: 25px;
} */
/* .sidemenu_list ul{
    display: flex;
    align-items: center;
    justify-content: space-around;
} */
@media (min-width: 320px) and (max-width: 479px) {
  .gold_button {
    right: 5px !important;
    bottom: 5px !important;
  }

  .gold_display1 button.gold_btn {
    width: 80px !important;
    font-size: 8px !important;
    padding: 0px 5px !important;
  }

  .display_gold button.gold_btn {
    width: 100px;
    font-size: 10px;
    padding: 0px 5px;
  }

  .category_name {
    font-size: 10px !important;
  }

  .gold_box {
    margin-bottom: 5px !important;
  }

  /* .cart_section_1 li:last-child {
        margin-left: 15px;
    } */
  .dailyrate {
    z-index: 9999 !important;
    top: 40px;
    left: 0px;
    width: 180px !important;
  }

  .submenu {
    left: 0px;
    top: 30px;
  }

  .logo img {
    cursor: pointer;
    width: 100%;
  }

  .header_main ul {
    margin-bottom: 0px;
  }

  .logo {
    text-align: center;
    /* width: calc(100% - 45px); */
  }

  ul.cart_section {
    width: 25%;
    margin-bottom: 0;
  }

  .cart_section li:first-child {
    margin-right: 12px;
  }

  .cart_section li:last-child {
    margin-left: 12px;
  }
}

@media (min-width: 480px) and (max-width: 639px) {
  .gold_button {
    right: 5px !important;
    bottom: 5px !important;
  }

  .display_gold button.gold_btn {
    width: 100px;
    font-size: 12px;
    padding: 0px 5px;
  }

  .category_name {
    font-size: 10px !important;
  }

  .logo img {
    width: 100%;
  }

  .login_form {
    width: 400px !important;
  }

  .image_sub {
    display: none;
  }
}

.disable_wifi {
    opacity: 0.25;
    pointer-events: none;
}

.main_wifi1_det {
    padding: 0px;
    margin: 0px;
}

.main_wifi1_det li {
    list-style-type: none;
    float: left;
    width: 33.33%;
    margin-bottom: 15px;
}

.main_wifi1_det img {
    margin-right: 5px;
}

.main_wifi1_det span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #545454;
}

.left_view_room li:last-child a {
    display: block;
}

.left_view_room li a {
    display: none;
}
/* .banner_container {
    margin-top: 141px;
} */
.banner_container img {
    width: 100%;
}
/* .menu_header{
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
} */
.gold_heading {
    text-align: center;
    padding: 40px 0px 20px 0px;
}

.gold_heading span {
    position: relative;
    font-family: Varela;
    text-transform: uppercase;
    color: #C08920;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
}
.gold_heading span::before {
    position: absolute;
    content: '';
    border-bottom: 4px double;
    top: 18px;
    width: 150px;
    left: -160px;
    color: rgba(217, 217, 217, 1);
}
.gold_heading span::after {
    position: absolute;
    content: '';
    border-bottom: 4px double;
    top: 18px;
    width: 150px;
    right: -160px;
    color: rgba(217, 217, 217, 1);
}
.gold_silver {
    text-align: center;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 5px;
    text-transform: uppercase;
    margin-top: 20px;
}
.gold_image img {
    cursor: pointer;
    width: 100%;
}
.gold_collect_cont .gold_image img {
    border-radius: 6px
}
.gold_image {
    padding: 0px 15px;
}

.gold_name {
    color: rgb(50, 32, 28);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.714px;
    text-transform: uppercase;
    padding: 15px 0px;
}

.view_banner img {
    width: 100%;
    margin-top: 50px;
    position: relative;
    cursor: pointer;
}

.display_gold_cont {
    display: flex;
    align-items: center;
}

.gold_display {
    margin-right: 20px;
    width: 58%;
}

.gold_display1 {
    width: 38%;
}
.ring_main {
    margin-top: 80px;
}
.gold_box {
    margin-bottom: 20px;
}

.display_gold {
    position: relative;
    overflow: hidden;
}

.display_gold_ani{
    position: relative;
    overflow: hidden;
}
.display_gold_ani:hover img {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: 0.3s all ease-in-out;
}
.display_gold_ani img {
    transition: 0.3s all ease-in-out;
}
.category_image_ani{
    overflow: hidden; 
}
.category_image_ani:hover img {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: 0.3s all ease-in-out;
}
.category_image_ani img {
    transition: 0.3s all ease-in-out;
}
.ring_box {
    padding: 30px 20px 0px 20px;
}
.gold_button{
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.gold_btn {
    border: 1px solid rgb(229 229 229 / 25%);
   position: relative;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    padding: 10px 20px;
    width: 225px;
    display: flex;
    border-radius: 4px;
    background: rgb(0 0 0 / 0%) ;
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    align-items: center;
    justify-content: space-between;
}
.gold_btn::before{
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 0%;
    background-color:rgb(8 8 8 / 42%) ;
    border-radius: 4px;
    transition: 0.3s all ease-in-out;
}
.gold_btn:hover::before {
    color: rgb(255, 255, 255);
    width: 100%;
    transition: 0.3s all ease-in-out;
}

.gold_btn:hover {
    color: rgb(255, 255, 255);
}
.ring_main_cont {
    background: rgb(255, 255, 255);
    border-radius: 5px;
}

.ring_box_img img {
    border-radius: 6px;
    width: 100%;
}

/* .main_text {
    color: rgb(22, 34, 91);
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    padding: 10px 0px;
} */

.main_text {
    margin-bottom: 20px;
    color: rgb(224, 150, 39);
    font-family: Varela;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 5px;
    text-transform: uppercase;
}
.tab_content {
    text-align: center;
}
.tab_head {
    color: rgb(191, 191, 191);
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}
.tab_content {
 
    text-align: center;
}
.tab_img {
    margin-bottom: 12px;
}
.tab_text {
    color: rgb(218, 205, 205);
    text-align: center;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.gold_tabs{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.gold_tab_cont {
    margin-top: -65px;
    padding: 100px 0px 30px 0px;
    background-image: url(/static/media/bg.9528cb4f.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.read_btn {
    position: relative;
    margin: 20px 20px;
    float: right;
    color: rgb(53, 35, 33);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    padding: 10px 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 5px;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid rgb(228, 228, 228);
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
}

.read_btn::before{
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    color: rgb(0, 0, 0);
    height: 100%;
    width: 0%;
    background-color:rgb(177 127 81 / 63%);
    border-radius: 4px;
    transition: 0.3s all ease-in-out;
}
.read_btn:hover::before {
    color: rgba(0, 0, 0, 0);
    width: 100%;
    transition: 0.3s all ease-in-out;
}

.read_btn:hover {
    color: rgb(53, 35, 33);
}

.read_btn span {
    margin-left: 25px;
}

.ring_cont .col-sm-6 {
    padding: 0px;
}
.category_container {
    display: flex;
    /* padding-bottom: 40px; */
    padding: 0px 20px 20px 20px;
}
.category_container .slick-track {
    margin-top: 20px;
}
.category_container .slick-initialized .slick-slide {
    width: 315px !important;
    margin-right: 6px;
    display: block;
}
.ring_cont {
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
    background: rgb(255, 255, 255);
    padding: 0px 15px;
    /* z-index: 1; */
    position: relative;
}
.ring_img {
    padding: 3px;
}

/* .sub_text {
    color: #333333;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding: 10px 0px;
} */
.sub_text {
    color: rgb(51, 51, 51);
    text-align: justify;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.looknew {
    margin-bottom: 60px;
}

.all_prod {
    position: absolute;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #FFD27D;
}

@media (min-width:320px) and (max-width:479px) {
    .category_box {
        margin-bottom: 20px;
    }
  
    .gold_heading span {
        font-size: 30px;
    }

    .gold_silver {
        font-size: 12px;
    }

    .gold_name {
        font-size: 14px;
    }

    .main_text {
        font-size: 16px;
    }

    .sub_text {
        font-size: 12px;
    }

    .gold_heading {
        padding: 20px 0px;
    }

    .ring_main {
        margin-top: 30px;
        padding: 40px 15px;
    }
}

@media (min-width:480px) and (max-width:639px) {
    .category_box {
        margin-bottom: 20px;
    }
    .gold_heading span {
        font-size: 30px;
    }

    .gold_silver {
        font-size: 12px;
    }

    .gold_name {
        font-size: 14px;
    }

    .main_text {
        font-size: 16px;
    }

    .sub_text {
        font-size: 12px;
    }

    .gold_heading {
        padding: 20px 0px;
    }

    .ring_main {
        margin-top: 30px;
        padding: 40px 15px;
    }
}

.catregory_cont .slick-track {
    display: flex;
}

.category_box {
    margin-right: 5px;
    border-radius: 10px;
    border-bottom: 4px solid rgb(177, 127, 81);
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
    padding: 8px;
    cursor: pointer;
}

.catregory_cont .slick-slide>div {
    padding: 0 13px;
}

.category_box a {
    float: left;
    width: 100%;
}

.category_image img {
    width: 100%;
    border-radius: 5px;
}

.category_name {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 0px;
    text-transform: uppercase;
    /* font-size: 16px; */
    /* font-weight: 500; */
    /* color: rgb(19 26 125); */
    /* letter-spacing: 0px; */
    line-height: 25px;
    /* text-align: center; */
    padding: 14px 0px 6px 0px;
}
.gold_collect_cont {
    margin-bottom: 20px;
    border-radius: 10px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
    padding: 5px 5px 0px 5px;
}



@media (min-width:768px){

    .menu_header {
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
    }
    .banner_container {
        margin-top: 141px;
    }
    .collection_banner {
        margin-top: 141px;
    }
    .detail_container{
        margin-top: 141px;
    }
    .login_main {
        margin-top: 141px;
    }
}
.login_main {
    background-image: url(/static/media/pop_background.2a5dc6e9.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
    min-height: calc(100vh - 404px);
}

.login_maindiv {
    /* position: absolute; */
}

.welcome {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    text-transform: uppercase;
    color: rgb(180 126 85);
    border-bottom: 1px solid rgb(180 126 85);
    padding: 15px 0px;
}

.login_form {
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px rgba(193, 177, 188, 0.1);
    border-radius: 8px;
    padding: 8px 34px;
    width: 557px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.mob_number {
    font-family: 'montserrat';
    font-weight: 300;
    font-size: 15px;
    color: rgb(0, 0, 0);
    padding: 15px 0px;
}

.mob_number input {
    outline: none;
    border: none;
    border-bottom: 1px solid rgb(199, 199, 199);
    width: 100%;
    margin-top: 8px;
}

.forgot_password {
    font-family: 'montserrat';
    color: rgb(0, 0, 0);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    float: right !important;
    text-align: right;
    width: 100%;
    margin: 0px 0px 18px;
}

.forgot_password_reg {
    font-family: 'montserrat';
    margin: 18px 0px;
    color: rgb(0, 0, 0);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.forgot_password span {
    cursor: pointer;
    color: rgb(180 126 85);
}

.forgot_password_reg span {
    cursor: pointer;
    color: rgb(180 126 85);
}

.login {
    background: rgb(52 34 32);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    align-items: center;
    border: none;
    text-align: center;
    outline: none;
    color: rgb(255, 255, 255);
}

.login_form .row {
    align-items: center;

}

.mob_number label {
    margin: 0px;
}

.login_btn {
    margin-top: 20px;
}

.login_btn button {
    width: 100%;
    padding: 10px 0px;
}

/* .image_sub img {
    width: 100%;
} */
.image_sub {
    position: relative;
}

.image_sub::before {
    content: '';
    height: 75px;
    width: 1px;
    background-color: rgb(180 126 85);
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    bottom: 70px;
}

.image_sub::after {
    content: '';
    height: 70px;
    width: 1px;
    background-color: rgb(180 126 85);
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 70px;
}

.mob_number input::-webkit-input-placeholder {
    color: rgba(60, 60, 60, 0.42);
    font-size: 12px;
}

.mob_number input::placeholder {
    color: rgba(60, 60, 60, 0.42);
    font-size: 12px;
}

@media (max-width:767) {
    .login_form {
        width: 228px;
    }
}

@media (min-width:320px) and (max-width:479px) {
    .login_form {
        width: 270px;
    }

    .welcome {
        font-size: 18px;
        padding: 6px 0px;
    }

    .mob_number {
        font-size: 12px;
    }

    .image_sub {
        display: none;
    }

    .menu_sub1 li {

        text-align: center;
    }

    .menu_sub li {
        margin-left: 10px !important;
    }
}
.details {
    color: rgb(52 34 32);
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    padding: 18px 0px 4px;
}

.welcome_reg {
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    text-transform: uppercase;
    color: rgb(177, 127, 81);
    border-bottom: 1px solid rgb(177, 127, 81);

}

.login_form_reg {
    padding: 34px;
}

.mob_numbers {
    margin-top: 26px;
}

.image_sub1 {
    position: relative;
}

.image_sub1::before {
    content: '';
    height: 234px;
    width: 1px;
    background-color: rgb(177, 127, 81);
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    bottom: 70px;
}

.image_sub1::after {
    content: '';
    height: 234px;
    width: 1px;
    background-color: rgb(177, 127, 81);
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 70px;
}

.enquiry_head {
    font-family: "FuturaNormal";
    font-size: 25px;
    font-weight: 400;
    color: rgb(52 34 32);
    margin-bottom: 22px;
    text-align: center;
}

.enquiry_cont .form-group input {
    font-family: "FuturaNormal";
    font-size: 16px;
    font-weight: 400;
    color: rgb(52 34 32);
    line-height: 23px;
    border: 1px solid #767676;
    border-radius: 5px;
    outline: none;
    float: left;
    width: 100%;
    padding: 16px 20px;
}

.mode_btn {
    text-align: center;
    margin-top: 35px;
}

.mode_btn button {
    font-family: "FuturaNormal";
    font-size: 20px;
    color: #fff;
    line-height: 13px;
    background-color: rgb(52 34 32);
    border: none;
    border-radius: 4px;
    padding: 18px 70px;
}

.modalPop {
    position: fixed;
    background-color: rgb(105 93 93 / 60%);
    z-index: 1050;
    width: 100%;
    height: 100vh;
    transition: all .5s ease-in-out;
    top: 0;
    left: 0;
    overflow: auto;
}

@media (max-width: 767px) {

    .image_sub1:after,
    .image_sub1:before {
        display: none;
    }

    .image_sub1 {
        display: none;
    }

    .details {
        font-size: 12px;
    }
}
.forgot_screen{
    padding: 26px 34px;
}
.reset_form{
    padding: 30px 30px;
}
.mode_box input {
    margin: 14px 0px;
}
.product_container {
    padding: 40px 0px;
}

.product_bar {
    background-color: rgba(247, 247, 247, 1);
    padding: 10px;
}

.product_bar input {
    border: 1px solid rgba(247, 247, 247, 1);
    border-radius: 18px;
    font-family: 'Varela';
    font-size: 15px;
    font-weight: 400;
    color: rgb(0, 0, 0);
    line-height: 17px;
    float: left;
    width: 100%;
    padding: 10px;
    outline: none;
    padding-left: 40px;
}

.product_bar input::-webkit-input-placeholder {
    color: rgba(165, 163, 163, 1);
}

.product_bar input::placeholder {
    color: rgba(165, 163, 163, 1);
}

.product_bar span {
    float: left;
    width: 100%;
    position: relative;
}

.product_bar span::before {
    content: '\f002';
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    /* background-image: url("../../../assets/images/pink.png"); */
    height: 18px;
    width: 18px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    left: 12px;
    top: 8px;
    color: rgb(189 189 189);
}



.product_box {
    border-radius: 5px;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
    background-color: rgba(247, 247, 247, 1);
    padding: 20px;
    margin-top: 40px;
    cursor: pointer;
}

.product_image {
    margin-bottom: 20px;
}

.product_desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product_name {
    font-family: 'montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #333333;
    line-height: 17px;
    text-transform: uppercase;
    width: 50%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.product_rate {
    font-family: 'montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    line-height: 17px;
    width: 50%;
    text-transform: uppercase;
    text-align: right;
}

.product_rate span {
    font-family:  'montserrat';
}

.product_weight {
    font-family: 'montserrat';
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    line-height: 17px;
    text-transform: uppercase;
    margin-top: 10px;
}
.product_image img {
    width: 100%;
}

.product_box {
    position: relative;
}

.heart {
    width: 30px;
    height: 30px;
    text-align: center;
    color: rgb(203 203 203);
    background-color: rgb(247 247 247);
    line-height: 34px;
    border-radius: 50%;
    position: absolute;
    font-size: 18px;
    box-shadow: 0px 0px 5px rgb(215 215 215 / 49%);
    right: 30px;
    top: 30px;
    cursor: pointer;
}

.heart_red {
    color: rgb(243 33 0) !important;
}

.breadcrumbs {
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
    color: rgba(115, 115, 115, 1);
    line-height: 17px;
    margin-bottom: 15px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.breadcrumbs span {
    color: rgba(115, 115, 115, 1);
}

.filter_head {
    padding: 10px 0px;
    border-bottom: 1px solid rgba(231, 231, 231, 1);
}

.filter_head span {
    font-family: 'Varela', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #2D2A2A;
    text-transform: uppercase;
    text-decoration: none !important;
    float: left;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    transition: 0.3s all ease-in-out;
}

.filter_head a:hover {
    color: #2D2A2A;
}

.filter_head a.collapsed i {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    transition: 0.2s all ease-in-out;
}

.filter_head a i {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    transition: 0.2s all ease-in-out;
}

.filter_cont {
    padding-top: 24px;
}

.filter_cont .form-group {
    display: block;
    margin-bottom: 16px;
}

.filter_cont .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.filter_cont .form-group label {
    position: relative;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #727272;
    letter-spacing: 0px;
    display: flex;
    align-items: center;
    line-height: 26px;
}

.filter_cont .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid rgb(54 35 33);
    border-radius: 1px;
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 15px;
}

.filter_cont .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADgSURBVHgB7ZS9C0FRGMafeyPyEYOURRaxm5WdyWhntnJnWclG+QMMGHxkMrCxGSUfixJ1ZUHuvc65k+F+qKMs91dnOKfeX+976nm5uN8fkexSCgzIMsTN5dbnYgHflNxTYOT5ekR4/BBLpk00HITH5WCXUZFQSMPrcrLJQgEfhHwGpVoXx/P1e5mQT6vFn6JGOYdKa6Ar0pV1JgtUi1l1LC/5Hypq9+ZYH04wwqb1SIsqzaHaocftxHi2wogcMwzjRMdLJqKk0yXMoHGysvlnmU1RuDrZlTswwHP8five92/UpUY185syvQAAAABJRU5ErkJggg==);
    left: 3px;
    top: 6px;
    background-size: cover;
    background-position: center;
}

.filter_input {
    display: flex;
    align-items: center;
}

.filter_input span {
    width: 40%;
    margin-right: 15px;
    position: relative;
}

.filter_input input {
    float: left;
    width: 100%;
    border: 1px solid rgba(110, 110, 110, 1);
    border-radius: 5px;
    font-family: 'FuturaNormal';
    color: rgb(0, 0, 0);
    padding: 15px 15px 15px 35px;
    font-size: 18px;
    outline: none;
}

.filter_input input::-webkit-input-placeholder {
    color: rgba(182, 182, 182, 1);
}

.filter_input input::placeholder {
    color: rgba(182, 182, 182, 1);
}

.filter_input span::before {
    content: '\e1bc';
    font-weight: 600;
    font-family: 'Font Awesome 5 Free';
    position: absolute;
    left: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

.filter_input button {
    font-family: 'FuturaNormal';
    font-size: 18px;
    font-weight: 400;
    color: rgb(255 255 255);
    background-color: rgba(0, 0, 0, 1);
    border-radius: 5px;
    border: none;
    width: 20%;
    padding: 15px;
}

.pagination {
    justify-content: flex-end;
    margin-top: 40px !important;
}

.pagination li {
    margin-left: 10px;
}



.pagination li:first-child a,
.pagination li:last-child a {
    width: auto;
    height: auto;
    border: none;
    text-transform: uppercase;
}


/* .filter_cont {
    overflow: hidden;
    transition: max-height 0.6s ease;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


/* 
.filter_cont {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
}

.filter_cont.show {
    transition: max-height 0.6s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
}
 */
 .filter_btn {
    background-color: rgb(39 20 15);
    border: none;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding: 5px 15px;
    box-shadow: 0 0 4px rgb(68 68 68 / 60%);
    border-radius: 3px;
}

.filter_btn i {
    margin-right: 5px;
    font-size: 12px;
}

@media (max-width: 767px) {
    .gold_heading span::before{
        display: none !important;
    }
    .gold_heading span::after{
        display: none !important;
    }
    /* .pagination {
        display: block !important;
    } */
    .address_box li {
        font-size: 14px !important;
    }
    .pagination li {
        display: inline-block !important;
        margin-bottom: 5px !important;
    }

    .product_filter_cont {
        padding: 10px 20px;
        max-height: 400px;
        overflow: auto;
    }

    .product_filter {
        /* position: fixed;
        bottom: 0px;
        border-radius: 15px 15px 0px 0px;
        box-shadow: 0px -3px 6px rgb(160 160 160 / 40%);
        z-index: 11;
        overflow-y: auto;
        max-height: 0; */
        background-color: rgb(255 255 255);
        left: 0px;
        width: 100%;
        transition: 0.9s all ease-in-out;
        display: none;
    }

    .filter_display {
        display: block;
        transition: 0.9s all ease-in-out;
    }

    .filter_show {
        transition: 0.9s all ease-in-out;
        height: auto;
        max-height: 450px;
    }

    .product_filter_close {
        border: none;
        background-color: rgb(0 0 0);
        border-top: 1px solid rgb(180, 180, 180);
        text-align: center;
        color: rgb(255, 255, 255);
        font-size: 15px;
        font-family: 'FuturaNormal';
        padding: 10px;
        text-align: center;
    }

    .flex_center {
        display: flex;
        align-items: center;
    }

    .filler {
        flex-grow: 1;
    }

    .product_filter_open {
        border: none;
        background-color: rgb(0 0 0);
        border-top: 1px solid rgb(180, 180, 180);
        color: rgb(255, 255, 255);
        font-size: 15px;
        font-family: 'FuturaNormal';
        padding: 5px 15px;
        text-align: center;
        border-radius: 2px;
    }

    .modal-shadow {
        position: relative;
        overflow: hidden;
    }

    .modal-shadow::before {
        position: fixed;
        content: '';
        background-color: rgb(0 0 0 / 40%);
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100vh;
        z-index: 10;
    }

    .product_container {
        padding: 24px 0px;
    }
}


.collection_banner img {
    width: 100%;
}

.product_list {
    position: relative;
    min-height: 300px;

}

.product_loader {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 90%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;

}

.product_loader img {
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 60px);
    -webkit-animation: zoom-in-zoom-out 1s ease infinite;
            animation: zoom-in-zoom-out 1s ease infinite;
}

.nodata_text {
    color: rgba(56, 15, 10, 0.988);
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    margin-top: 40px;
}
.no_data_img {
    padding: 20px;
}

.nodata {
    text-align: center;
}

@-webkit-keyframes zoom-in-zoom-out {
    0% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
    }

    50% {
        -webkit-transform: scale(0.8, 0.8);
                transform: scale(0.8, 0.8);
    }

    100% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
    }
}

@keyframes zoom-in-zoom-out {
    0% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
    }

    50% {
        -webkit-transform: scale(0.8, 0.8);
                transform: scale(0.8, 0.8);
    }

    100% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
    }
}


@media (max-width: 767px) {
    .product_left {
        padding: 0px 5px;
    }

    .nodata img {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .product_container .col-md-10 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .product_container .col-md-2 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .product_list .col-md-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .product_name {
        font-size: 12px;
    }

    .product_rate {
        font-size: 12px;
    }

    .product_weight {
        font-size: 12px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .product_container .col-md-10 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .product_container .col-md-2 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .product_list .col-md-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
.detail_container {
  padding: 40px 0px;
}

.detail_left {
  height: 335px;
  text-align: center;
  position: relative;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
}

.product_imageee img {
  height: 650px;
  align-items: end;
  display: flex;
}

.detail_left img {
  max-width: 100%;
}

.enquiry_cont img {
  width: 100%;
}

.detail_name {
  font-family: "Varela";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #362321;
  text-transform: uppercase;
  line-height: 35px;
}
.detail_code {
  font-family: "montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #362321;
  line-height: 17px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.tot_colour {
  color: rgb(54, 35, 33) !important;
}

.detail_rate {
  font-family: "montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #4b4848;
  margin-top: 6px;
}

.detail_rate span {
}

.detail_rate b {
}

.detail_btn {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.detail_btn button {
  margin-right: 10px;
  border: 1px solid rgb(229 229 229 / 25%);
  position: relative;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 10px;
  width: 155px;
  display: flex;
  border-radius: 4px;
  background: rgb(54 35 33);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  align-items: center;
  justify-content: center;
}

.detail_note {
  margin-bottom: 25px;
}

.detail_note li {
  list-style-type: none;
  float: left;
  width: 50%;
  min-height: 28px;
  padding: 12px 10px;
}

.detail_note li {
  font-family: "montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #2d2a2a;
  line-height: 14px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.detail_note li a img {
  margin-right: 10px;
}

.detail_enq {
  font-family: "FuturaNormal";
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  color: rgba(0, 0, 0, 1);
  line-height: 28px;
  text-transform: uppercase;
}

.detail_pin_box {
  background-color: rgba(245, 245, 245, 1);
  padding: 30px;
  margin-top: 20px;
}

.detail_pin_label {
  font-family: "FuturaNormal";
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 17px;
  margin-bottom: 20px;
}

.detail_pin_cont {
  display: flex;
  align-items: center;
}

.detail_pin_cont input {
  font-family: "FuturaNormal";
  font-size: 15px;
  font-weight: 400;
  color: rgba(1, 0, 69, 1);
  line-height: 17px;
  outline: none;
  width: 70%;
  padding: 15px 20px;
  border: 1px solid rgba(1, 0, 69, 1);
  border-radius: 5px;
}

.detail_pin_cont input::-webkit-input-placeholder {
  color: rgba(181, 181, 181, 1);
}

.detail_pin_cont input::placeholder {
  color: rgba(181, 181, 181, 1);
}

.detail_pin_cont button {
  font-family: "FuturaNormal";
  font-size: 15px;
  color: rgba(255, 255, 255, 1);
  line-height: 8px;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 5px;
  padding: 20px;
  border: 1px solid rgb(0, 0, 0);
  height: 50px;
  width: 26%;
  margin-left: 3%;
}

.speification_head {
  font-family: "GFS Didot", serif;
  font-size: 30px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  line-height: 30px;
  text-align: center;
  margin-bottom: 50px;
}

.specification_main {
  margin-top: 90px;
}

.specification_box {
  border-bottom: 1px solid rgb(225 225 225);
  margin-bottom: 45px;
}

.specification_box:last-child {
  margin-bottom: 0px;
}

.specification_head {
  margin-bottom: 45px;
}

.specification_head a {
  font-family: "FuturaNormal";
  font-size: 25px;
  font-weight: 500;
  color: rgba(112, 81, 2, 1);
  line-height: 25px;
  text-decoration: none;
  position: relative;
  float: left;
  width: 100%;
}

.specification_cont li {
  list-style-type: none;
  font-family: "FuturaNormal";
  font-size: 20px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.specification_cont li b {
  font-family: "FuturaMedium";
  font-weight: 500;
}

.specification_head a::before {
  position: absolute;
  content: "\f067";
  font-family: "Font Awesome 5 free";
  font-weight: 600;
  right: 0px;
  top: 0px;
}

.specification_head a[aria-expanded="true"]::before {
  content: "\f068";
}

.related_main {
  margin-top: 50px;
}

.related_head {
  font-size: 20px;
  font-weight: 500;
  color: rgb(53 35 33);
  line-height: 30px;
  text-align: center;
  margin-bottom: 20px;
  font-family: "varela";
  text-transform: uppercase;
}

.related_box {
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
  background-color: rgba(247, 247, 247, 1);
  padding: 18px;
  position: relative;
}
.related_image {
  margin-bottom: 18px;
}

.related_desc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.related_name {
  font-family: "montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: rgb(54 35 33);
  text-transform: uppercase;
  width: 50%;
}

.related_rate {
  font-family: "montserrat";
  font-size: 17px;
  font-weight: 400;
  color: rgb(40 27 25);
  width: 50%;
  text-transform: uppercase;
  text-align: right;
}

.related_rate span {
  font-family: "montserrat";
}

.related_weight {
  font-family: "montserrat";
  font-size: 12px;
  font-weight: 400;
  color: rgb(54 35 33);
  text-transform: uppercase;
  margin-top: 8px;
}

.related_image img {
  width: 100%;
}

.related_cont .row {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: block;
  margin: 0px;
}

.related_cont .col-sm-3 {
  display: inline-block;
  max-width: 22%;
  /* padding: 0px 25px; */
}

.breadcrumbs1 {
  padding: 0px;
}

.price_breakup {
  text-transform: uppercase;
  color: rgb(54 35 33);
  font-family: "Varela", sans-serif;
  font-style: normal;
  font-weight: 500;
  padding: 10px;
  font-size: 16px;
}

.overprice {
  border-bottom: 4px solid rgb(177, 127, 81);
  border-radius: 4px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
}

.related_cont .row {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: block;
  margin: 0;
}

.related_cont .col-sm-3 {
  display: inline-block;
  max-width: 22%;
  /* padding: 0 25px; */
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  background-color: transparent;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: transparent;
}

@media (min-width: 768px) {
  .detail_container .col-sm-6:last-child {
    padding-left: 30px;
  }

  .detail_container .col-sm-6:first-child {
    padding-right: 30px;
  }
}

@media (max-width: 768px) {
  .related_head {
    font-size: 20px;
    margin-bottom: 38px;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .detail_btn {
    display: block;
  }
  .detail_btn button {
    margin-bottom: 10px;
  }
  /* .heart {
    
        top: -12px;
        right: 10px;
    } */
  .related_cont .col-sm-3 {
    display: inline-block;
    max-width: 75%;
    padding: 0 15px;
  }

  .related_name,
  .related_rate {
    font-weight: 400;
    color: #000;
    text-transform: uppercase;
    width: 50%;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .detail_btn {
    display: block;
  }
  .detail_btn button {
    margin-bottom: 10px;
  }
  /* .heart {
    
        top: -12px;
        right: 10px;
    } */
}
@media (min-width: 640px) and (max-width: 767px) {
  .detail_btn {
    display: block;
  }
  .detail_btn button {
    margin-bottom: 10px;
  }
}

.cart_img img {
  border-radius: 5px;
  width: 100%;
}
.main_cart {
  margin-top: 135px;
  padding: 40px;
  min-height: calc(100vh - 404px);
}
.quantity button {
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  background-color: rgb(54 35 33);
  color: white;
  border: none;
  text-align: center;
}
.cart_note {
  margin-bottom: 25px;
}
.cart_note li {
  font-family: "montserrat";
  font-size: 13px;
  padding: 12px 10px;
}
.detail_note1 {
  text-align: right;
}
.cartData {
  font-family: Varela;
  font-size: 24px;
  color: rgb(54, 35, 33);
  margin-bottom: 15px;
}
.cart_detail_box {
  margin-bottom: 30px;
  border-radius: 10px;
  border-top: 4px solid rgb(177, 127, 81);
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
  /* border: 1px solid rgba(64, 0, 0, 0.129); */
}
.cart_detail_box .price_breakup {
  padding: 14px 10px;
}
.order_sum {
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  border-top: 4px solid rgb(177, 127, 81);
  background: rgb(255, 255, 255);
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
  /* border: 1px solid #40000021; */
}

.cartEmpty {
  padding: 185px 0px 30px 0px;
  text-align: center;
  min-height: calc(100vh - 442px);
}
.cartempty_txt {
  font-size: 22px;
  font-weight: 700;
  padding: 13px;
  color: rgb(22, 34, 91);
}

@media (max-width: 768px) {
  .cart_detail_box {
    margin: 22px 0px;
  }

  .cartEmpty {
    padding: 30px;
  }
}

.shipping_container {
    margin-top: 145px;
    padding: 35px 100px;
}
.shipping_cont {
    border-radius: 10px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
    padding: 20px;
}
.shipping_head {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 500;
    color: rgb(54 35 33);
    letter-spacing: 0px;
    font-family: 'varela';
    line-height: 25px;
    margin-bottom: 32px;
}
.shipping_fields .form-group {
    margin-bottom: 20px;
}
.shipping_fields label {
    font-size: 15px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0px;
    line-height: 20px;
    margin-bottom: 18px;
    float: left;
    width: 100%;
    font-family: 'montserrat';
}
.shipping_fields input {
    font-size: 14px;
    float: left;
    width: 100%;
    border: none;
    border-bottom: 2px solid rgb(55, 35, 33);
    border-radius: 4px;
    height: 60px;
    outline: none;
    padding: 0px 15px;
    background: rgba(64, 0, 0, 0.031);
}
.shipping_note {
    
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 83, 83, 1);
    line-height: 18px;
    text-transform: uppercase;
    background-color: rgba(255, 234, 234, 1);
    border: 1px solid rgba(245, 0, 0, 1);
    border-radius: 5px;
    padding: 15px 25px;
    width: auto;
    display: flex;
    align-items: center;
}
.shipping_note i {
    color: rgba(245, 0, 0, 1);
    font-size: 28px;
    margin-right: 20px;
}
.shipping_fields span{
    margin-left: 6px;
   color: rgb(220, 53, 69);

}
.shipping_list {
    border-top: 1px dashed rgb(217 216 216);
    padding-top: 25px;
 
}
.shipping_list li {
    font-family: 'montserrat';
    list-style-type: none;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
}
.shipping_list li span {
    color: rgba(84, 84, 84, 1);
}
.shipping_list li b {
 
    font-weight: 300;
}
.shipping_list li:last-child {
    font-family: 'montserrat';
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    border-top: 1px dashed rgb(217 216 216);
    padding-top: 25px;
    border-bottom: 1px solid rgb(217 216 216);
}
.shipping_list li:last-child span {
    color: rgba(0, 0, 0, 1);
}

.shipping_mode [type="radio"]:checked,
.shipping_mode [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.shipping_mode [type="radio"]:checked + label,
.shipping_mode [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    line-height: 22px;
    display: inline-block;
    color: rgb(0,0,0);
    
    font-size: 18px;
}
.shipping_mode [type="radio"]:checked + label:before,
.shipping_mode [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    border: 1px solid rgb(0,0,0);
    border-radius: 100%;
    background: rgb(255,255,255);
}
.shipping_mode [type="radio"]:checked + label:after,
.shipping_mode [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: rgb(0, 0, 0);
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    transition: all 0.2s ease;
}
.shipping_mode [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.shipping_mode [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.shipping_mode {
    margin: 35px 0px;
}
.shipping_mode_head {

    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0px;
    line-height: 20px;
    margin-bottom: 18px;
}
.shipping_btn {
    background-color: rgb(54 35 33);
    border-radius: 5px;
    border: none;
    text-align: center;
    color: rgb(255,255,255);
    height: 60px;
    outline: none;
    font-family: 'montserrat';
    font-size: 18px;
    font-weight: 500;
}
.form-group select {
    font-size: 14px;
    float: left;
    width: 100%;
    border: none;
    border-bottom: 2px solid rgb(54 35 33);
    border-radius: 4px;
    height: 60px;
    outline: none;
    padding: 0px 15px;
    background: rgba(64, 0, 0, 0.031);
}

@media (max-width:768px){
   
    /* .shipping_container {
    padding: 40px 20px 40px 20px;
} */
.shipping_btn{
    height: 50px;
    font-size:18px
}
.shipping_list li:last-child{
    font-size: 16px;
}
.shipping_list li{
    font-size: 12px;
}
}
@media (max-width:767px){
    .shipping_container {
        margin-top: 0px!important;
        padding: 20px;
    }
}

.about_img img{
    width: 100%;
}
.about_main{
    padding: 10px;
}
.about_head {
    font-size: 22px;
    padding: 10px 0px;
}
.abt_container {
    padding: 25px 0px;
}
.about_head {
    font-family: 'Rochester', cursive;
    color: rgb(192, 137, 32);
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 50px;
    margin-bottom: 10px;
}
.about_body {
    font-family: 'Montserrat', sans-serif;
    color: rgb(51, 51, 51);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
    letter-spacing: 0.5px;
}
.about_img img {
    border-radius: 3px;
    box-shadow: 8px 8px 8px rgb(36 36 36 / 35%);
}
span.about_body img {
    border-radius: 6px;
    float: left;
    margin-right: 30px;
}
@media (min-width:320px) and (max-width:479px){
    .about_body img {
        margin-bottom: 20px;
    }
    .abt_container{
        padding: 0;
    }
    span.about_body img {
        width: 100%;
        float: left;
        margin-right: 30px;
    }
    .about_main{
    padding: 10px;
}
.contact_main {
    padding: 40px 0px !important;
}
.map_show{
    padding: 4px;
}
.contactMain {
    padding: 18px 0px;
}
}
@media (min-width:480px) and (max-width:639px){
    .about_body img {
        margin-bottom: 20px;
    }
    .abt_container{
        padding: 0;
    }
    span.about_body img {
        width: 100%;
        float: left;
        margin-right: 30px;
    }
}
@media (min-width:640px) and (max-width:767px){
    .about_body img {
        margin-bottom: 20px;
    } 
}

.contact_main{
    padding: 40px 0px;
}
.map_show iframe {
    width: 100%;
}
.submit_btn button {
    font-family: 'montserrat';
    border-radius: 4px;
    width: 100%;
    background-color: rgb(51 33 31);
    padding: 10px;
    color: white;
    margin-top: 10px;
    border: none;
}
.contactMain {
    border-radius: 5px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
    padding: 15px;
}

.address_head {
    font-family: 'montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    text-transform: uppercase;
    color: rgb(180 126 85);
    border-bottom: 1px solid rgb(180 126 85);
    padding: 15px 0px;
    margin-bottom: 25px;
}
.address_box li {
    font-family: 'montserrat';
    list-style-type: none;
    margin-bottom: 15px;
    font-size: 15px;
    position: relative;
    padding-left: 30px;
}
.address_box li::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    position: absolute;
    left: 0px;
    top: 0px;
    color: rgb(180 126 85);
}
.address_box li:nth-child(1):before {
    content: '\f879';
}
.address_box li:nth-child(2):before {
    content: '\f0e0';
}
.address_box li:nth-child(3):before {
    content: '\f3c5';
}
.thankyou_container {
  padding: 0px 20px;
}

.thankyou_image {
  text-align: center;
}

.thankyou_desk {
  font-family: "FuturaNormal";
  font-size: 25px;
  font-weight: 400;
  color: rgba(71, 71, 71, 1);
  text-align: center;
  letter-spacing: 0px;
  line-height: 40px;
}
.thankyou_box {
  padding-bottom: 40px;
  text-align: center;
  font-size: 26px;
  padding: 40px;
  color: rgb(22, 34, 91) !important;
}

.thankyou_image {
  padding: 150px 0px 0px 0px;
}

@media (max-width: 767px) {
  .thankyou_image {
    padding: 10px 0px !important;
  }
  .thankyou_box {
    padding: 20px;
  }

  .thankyou_image img {
    width: 100%;
  }
}

.myaccount {
    margin-top: 135px;
    padding: 40px;
}
.submit_btn button {
    font-family: 'montserrat';
    border-radius: 4px;
    width: 50%;
    background-color: rgb(51 33 31);
    padding: 10px;
    color: white;
    margin-top: 10px;
    border: none;
}
.mycontact {
    padding: 38px 0px;
}
.submit_btn {
    text-align: center;
}
.mob{
    opacity: 0.5;

}
@media (max-width:768px){
    .myaccount {
    padding: 40px 20px;
}
.mycontact {
    padding: 26px 0px;
}
}
.order_img img {
    width: 100%;
}

.invoice button {
    /* font-size: 11px; */
    font-family: 'Varela';
    padding: 10px 5px;
    border: none;
    border-radius: 4px;
    color: rgb(50 33 31);
    font-weight: 500;
}
.mainord {
    border-radius: 5px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
    margin-bottom: 36px;
  
    padding: 10px;
}
.mainord .row {
    align-items: center;
    justify-content: space-between;
}
.invoice button {
    float: left;
    width: 100%;

    font-size: 11px;

}


.order_name {
    font-size: 18px;
    /* color: #16225b; */
}
.price{
     font-size: 14px;
     /* color: #16225b; */
}
.product_info ul li {
    font-family: 'Montserrat';
    list-style: none;
    line-height: 23px;
    list-style: none;
    font-size: 12px;
}
.main_order {
    margin-top: 130px;
    padding: 50px;
}
.privacy{
    padding: 40px;
}
.privacyPolicy {
    font-size: 24px;
    color: #400000;
    margin-bottom: 20px;
}

.privacy_list{
    list-style-type: none;
}
.privacy_sub_list{
    margin-bottom: 20px;
    padding-left: 20px;
}
.privacy{
    padding: 40px;
    min-height: calc(100vh - 442px);
}
.privacyPolicy {
    font-size: 24px;
    color:rgb(22, 34, 91);
    margin-bottom: 20px;
}

.privacy {
    margin-top: 145px;
    padding: 40px;
    min-height: calc(100vh - 442px);
    word-break: break-word;
    text-align: justify;
}
.privacyPolicy {
    font-size: 24px;
    color: #400000;
    margin-bottom: 20px;
}
.privacy_sub{
    font-size: 20px;
    color: #400000;
    margin-bottom: 5px;
}
.privacy_li{
    font-size: 15px;
    color: #400000;
}
@media (max-width: 767px) {

    .privacy{
        margin-top: 0px !important;
        padding: 40px 20px;
    }
}
.thankyou_container {
  padding: 0px 20px;
}

.thankyou_image {
  text-align: center;
}

.thankyou_desk {
  font-family: "FuturaNormal";
  font-size: 25px;
  font-weight: 400;
  color: rgba(71, 71, 71, 1);
  text-align: center;
  letter-spacing: 0px;
  line-height: 40px;
}
.thankyou_box {
  padding-bottom: 40px;
  text-align: center;
  font-size: 26px;
  padding: 40px;
  color: rgb(22, 34, 91) !important;
}

.thankyou_image {
  padding: 150px 0px 35px 0px;
}

@media (max-width: 767px) {
  .thankyou_image {
    padding: 10px 0px !important;
  }
  .thankyou_box {
    padding: 20px;
  }

  .thankyou_image img {
    width: 100%;
  }
}

